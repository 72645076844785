import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { userAccess } from '../model/userAccess.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private token: string | null = null; // In-memory token
  private username: string | null = null; // In-memory token
  private password: string | null = null; // In-memory token
  baseApiUrl: string = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  fetchToken(tokenId: string): Observable<any> {
    return this.http.get(`${this.baseApiUrl}/api/token/${tokenId}`);
  }

  setToken(token: string): void {
    this.token = token;
    console.log("the token ", this.token)
  }

  getToken(): string | null {
    return this.token || localStorage.getItem('authToken');
  }

  clearToken(): void {
    this.token = null;
    localStorage.removeItem('authToken');
  }

  // getAccess(token: string, menu: number): Observable<userAccess[]> {
  //   return this.http.get<userAccess[]>(`${this.baseApiUrl}/api/token?config=${encodeURIComponent(token)}`);
  // }

  getAccess(token: string, menu: number): Observable<userAccess[]> {
    return this.http.get<userAccess[]>(`${this.baseApiUrl}/api/token?config=${encodeURIComponent(token)}&menuId=${menu}`);
  }
}
