import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverlistComponent } from './component/DriverMaintenance/driverlist/driverlist.component';
import { AddEditDriverComponent } from './component/DriverMaintenance/add-edit-driver/add-edit-driver.component';
import { TrucklistComponent } from './component/TruckMaintenance/trucklist/trucklist.component';
import { AddEditTruckComponent } from './component/TruckMaintenance/add-edit-truck/add-edit-truck.component';
import { DriverBlacklistComponent } from './component/driver-blacklist/driver-blacklist.component';
const routes: Routes = [
  { path: '', component: AddEditDriverComponent },
  { path: 'driver/list', component: DriverlistComponent },
  { path: 'driver/form', component: AddEditDriverComponent },
  { path: 'truck/list', component: TrucklistComponent },
  { path: 'truck/form', component: AddEditTruckComponent },
  { path: 'blacklist', component: DriverBlacklistComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
