import { Component, OnInit, HostListener } from '@angular/core';
import { TruckService } from '../../../service/truck.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TruckFilterCriteria } from '../../../model/truckFilterCriteria.model';
import { RowDoubleClickedEvent, GridApi } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { Truck } from '../../../model/truck.model';
import { TokenService } from '../../../service/token.service';
import { userAccess } from '../../../model/userAccess.model';
@Component({
  selector: 'app-trucklist',
  templateUrl: './trucklist.component.html',
  styleUrls: ['./trucklist.component.css'],
})
export class TrucklistComponent implements OnInit {
  searchForm: FormGroup;
  displayDropdown = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  criteriaList: TruckFilterCriteria[] = [];
  filteredRowData: any[] = [];
  isLoading: boolean = false;
  selectedFilter: string | null = null;
  selectedTruck: Truck | null = null;
  access: any[] = [];
  userHasEditAccess: boolean = false;
  userHasCreateAccess: boolean = false;
  userHasViewAccess: boolean = false;
  userHasDeleteAccess: boolean = false;
  Token: string = '';
  columnDefs: ColDef[] = [
    { field: 'plateNo', headerName: 'Plate No' },
    { field: 'truckName', headerName: 'Trucker Name' },
    { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
    { field: 'status', headerName: 'Status' },
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  };


  constructor(private truckService: TruckService, private fb: FormBuilder, private router: Router, private tokenService: TokenService, private route: ActivatedRoute) {
    this.searchForm = this.fb.group({
      selectedOption: ['plateNo'],
      plateNoFrom: [''],
      plateNoTo: [''],
      truckerNameFrom: [''],
      truckerNameTo: [''],
      startDate: [''],
      endDate: [''],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['config']) {
        try {
          // Decode and parse the 'config' from query params
          const config = JSON.parse(decodeURIComponent(params['config']));
          console.log('Token stored:', config.Id);
          console.log('Menuid stored:', 'menuId:', params['menuId']);
          // Set the token using the token service
          this.tokenService.setToken(config.Id);
          const menuId = params['menuId']
          // Now that the token is set, proceed to call getAccess with the token
          this.tokenService.getAccess(config.Id, menuId).subscribe({
            next: (data: userAccess[]) => {
              console.log('Access data received:', data);
              this.access = data;
              this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
              this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
              this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
              this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
            },
            error: (err) => console.error('Error loading access data:', err),
          });
        } catch (error) {
          console.error('Error parsing config JSON:', error);
        }
      } else {
        console.warn("Config parameter missing from URL query params.");
      }
    });

    const storedCriteriaList = JSON.parse(localStorage.getItem('criteriaList') || '[]');
    if (storedCriteriaList.length > 0) {
      this.criteriaList = storedCriteriaList; // Set the criteria list from local storage
      this.searchTrucks(); // Search with the retrieved criteria
    }

    this.truckService.getAllTruck().subscribe({
      next: (data) => {
        // Handle the retrieved truck data if needed
      },
      error: (response) => {
        console.error('Error fetching trucks:', response);
      },
    });
  }

  // START DATE FORMAT
  dateFormatter(params: any): string {
    const date = new Date(params.value);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString();
    }
    return params.value;
  }
  // END DATE FORMAT

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition = {
      x: `${event.pageX}px`,
      y: `${event.pageY}px`
    };
    this.displayDropdown = true;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    this.displayDropdown = false;
  }

  onSearch() {
    if (this.criteriaList.length === 0) {
      this.filteredRowData = [];
      return;
    }
    this.isLoading = true;
    this.searchTrucks();
    console.log(this.criteriaList)
  }

  searchTrucks() {
    this.truckService.searchTruck(this.criteriaList).subscribe({
      next: (data) => {
        this.filteredRowData = data;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  selectFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedFilter = target.value;
  }

  getCriteriaLabel(criteria: TruckFilterCriteria): string {
    switch (criteria.selectedOption) {
      case 'plateNo':
        return `Plate No: ${criteria.plateNoFrom || ''} - ${criteria.plateNoTo || ''}`;
      case 'createdDate':
        return `Created Date: ${criteria.startDate ? criteria.startDate.toLocaleDateString() : ''} - ${criteria.endDate ? criteria.endDate.toLocaleDateString() : ''}`;
      case 'truckerName':
        return `Trucker Name: ${criteria.truckerNameFrom || ''} - ${criteria.truckerNameTo || ''}`;
      default:
        return '';
    }
  }

  // START SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING
  private setDefaultToValues(criteria: TruckFilterCriteria) {
    if (!criteria.plateNoTo) {
      criteria.plateNoTo = criteria.plateNoFrom;
    } else if (!criteria.plateNoFrom) {
      criteria.plateNoFrom = criteria.plateNoTo;
    }
    if (!criteria.truckerNameTo) {
      criteria.truckerNameTo = criteria.truckerNameFrom;
    } else if (!criteria.truckerNameFrom) {
      criteria.truckerNameFrom = criteria.truckerNameTo;
    }
    if (!criteria.endDate) {
      criteria.endDate = criteria.startDate;
    } else if (!criteria.startDate) {
      criteria.startDate = criteria.endDate;
    }
  }
  // END SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING

  // START ADD CRITERIA
  addCriteria() {
    const criteria: TruckFilterCriteria = this.searchForm.value;

    this.setDefaultToValues(criteria);
    // Convert to number if necessary
    criteria.truckerNameFrom = criteria.truckerNameFrom ? Number(criteria.truckerNameFrom) : null;
    criteria.truckerNameTo = criteria.truckerNameTo ? Number(criteria.truckerNameTo) : null;

    const existingIndex = this.criteriaList.findIndex(
      (item) => item.selectedOption === criteria.selectedOption
    );

    if (existingIndex !== -1) {
      this.criteriaList[existingIndex] = criteria;
    } else {
      this.criteriaList.push(criteria);
    }

    // Store updated criteria in local storage
    localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));
  }


  removeCriteria(index: number) {
    if (index < 0 || index >= this.criteriaList.length) {
      console.error('Index out of bounds. Cannot remove criteria.');
      return;
    }


    const removedCriterion = this.criteriaList[index]; // Get the removed criterion
    this.criteriaList.splice(index, 1); // Remove the criterion from the list

    // Reset form controls based on the removed criterion
    this.resetFormControls(removedCriterion.selectedOption);

    // Update local storage after removing a criterion
    // this.criteriaList = [];
    // this.filteredRowData = [];
    // localStorage.removeItem('criteriaList'); // Clear local storage
    // localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));

    // If necessary, you may want to trigger a search after removing a criterion
  }


  // removeCriteria(index: number) {
  //   if (index < 0 || index >= this.criteriaList.length) {
  //     console.error('Index out of bounds. Cannot remove criteria.');
  //     return;
  //   }

  //   const removedCriterion = this.criteriaList[index]; // Get the removed criterion
  //   this.criteriaList.splice(index, 1); // Remove the criterion from the list

  //   // Reset form controls based on the removed criterion
  //   this.resetFormControls(removedCriterion.selectedOption);

  //   // Update local storage after removing a criterion
  //   localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList)); // Update local storage with the updated list

  //   // If necessary, trigger a search or update the filtered data after removing a criterion
  //   if (this.criteriaList.length === 0) {
  //     // If no criteria left, clear filtered data
  //     this.filteredRowData = [];
  //   } else {
  //     // Trigger a search or update the filtered data
  //     this.onSearch();
  //   }
  // }

  resetFormControls(selectedOption: string) {
    switch (selectedOption) {
      case 'plateNo':
        this.searchForm.patchValue({
          plateNoFrom: '',
          plateNoTo: '',
        });
        break;
      case 'truckerName':
        this.searchForm.patchValue({
          truckerNameFrom: '',
          truckerNameTo: '',
        });
        break;
      case 'createdDate':
        this.searchForm.patchValue({
          startDate: '',
          endDate: '',
        });
        break;
      default:
        break;
    }
  }

  // END REMOVE INPUTS WHEN CRITERIA IS REMOVED

  private gridApi!: GridApi;

  onRowDoubleClicked(event: RowDoubleClickedEvent<any>) {
    const rowData = event.data.id;
    if (this.userHasViewAccess) {
      if (rowData) {
        this.router.navigate(['/truck/form'], { state: { fieldDisabled: true, truckId: rowData } });
      } else {
        console.error('Row data or ID is undefined.');
      }
    }
  }


  onEditTruck() {
    console.log("selected truck ", this.selectedTruck)
    if (this.selectedTruck) {
      this.router.navigate(['/truck/form'], { state: { fieldDisabled: false, truckId: this.selectedTruck } });
    }
    this.displayDropdown = false;
  }


  onGridReady(params: { api: GridApi }) {
    this.gridApi = params.api;
  }

  onCellContextMenu(event: CellContextMenuEvent) {
    if (event && event.event) {
      this.selectedTruck = event.node.data.id;
      this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
      this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
      this.displayDropdown = true;
      event.event.preventDefault();
    }
  }

  resetCriteria() {
    this.criteriaList = [];
    this.filteredRowData = [];
    localStorage.removeItem('criteriaList'); // Clear local storage
  }
  @HostListener('window:beforeunload', ['$event'])
  clearLocalStorage(event: Event): void {
    localStorage.clear();  // Clears all localStorage data
  }
}
