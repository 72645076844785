<div class="container-fluid border p-3">
    <!--START FORM -->
    <div class="container-fluid mt-3 px-xl-5">
        <div class="row d-flex justify-content-between">
            <!--START PERSONAL INFO -->
            <div class="left-content col-12 col-lg-6 col-xl-6 mb-3">
                <!-- TOAST -->
                <h5 class="mb-3">Personal Information</h5>
                <app-toast #toastComponent></app-toast>
                <div class="text-start text-lg-end">
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">First Name:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="is-invalid form-control" placeholder="" id="firstName"
                                name="firstName" [(ngModel)]="addDriverRequest.firstName">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Last Name:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="lastName" name="lastName" class="is-invalid form-control
                            :'form-control'" [(ngModel)]="addDriverRequest.lastName">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Middle Name:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="lastName" name="lastName"
                                [(ngModel)]="addDriverRequest.middleName">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Nick Name:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="lastName" name="lastName"
                                [(ngModel)]="addDriverRequest.nickName">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Contact No.:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="contactNumber"
                                name="contactNumber" [(ngModel)]="addDriverRequest.contactNumber">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Years of Service:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="yearsOfService"
                                name="yearsOfService" [(ngModel)]="addDriverRequest.yearsOfService">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">License No:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="licenseNo" name="licenseNo"
                                [(ngModel)]="addDriverRequest.licenseNo">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">licenseType</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="licenseType" name="licenseType"
                                [(ngModel)]="addDriverRequest.licenseType">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">License Expiration Date:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="date" class="form-control" id="licenseExpiry" name="licenseExpiry"
                                [(ngModel)]="addDriverRequest.licenseExpiry">
                        </div>
                    </div>
                </div>
            </div>
            <!--END PERSONAL INFO -->

            <!--START 3PL INFORMATION -->
            <div class="right-content col-12 col-lg-6 col-xl-6 mb-3">
                <h5 class="mb-3">3PL Information</h5>
                <div class="text-end">
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Company Name:</label>
                        </div>
                        <div class="col-12 col-lg-8 d-flex gap-2">
                            <input type="text"
                                [ngClass]="addDriverRequest.truckerId === 0 && isSubmited ? 'is-invalid form-control':'form-control'"
                                placeholder="Selected Trucker" [(ngModel)]="addTruckerRequest.name" readonly
                                (click)="openModal()">
                            <input type="hidden" id="truckerId" name="truckerId"
                                [(ngModel)]="addDriverRequest.truckerId">
                            <span class="input-group-text" id="basic-addon1" (click)="openModal()">Search</span>
                        </div>
                    </div>

                    <!-- START TRUCKER ID MODAL -->
                    <div class="modal fade " id="truckerModal" tabindex="-1" aria-labelledby="truckerModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="truckerModalLabel">Company Name</h1>
                                    <button type="button" class="btn-close" (click)="closeModal()"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body ">
                                    <!-- SEARCH -->
                                    <div class="d-flex gap-2 mb-3">
                                        <input type="text" class="form-control" placeholder="Search"
                                            [(ngModel)]="searchDriver" (keypress)="onkeypress($event)">
                                        <span class="input-group-text" id="basic-addon1"
                                            (click)="onSearch()">Search</span>
                                    </div>
                                    <div class="overflow-scroll" style="height: 30vh;">
                                        <div class="table-responsive">
                                            <table class="table" *ngIf="trucker && trucker.length > 0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let trucker of trucker"
                                                        (click)="selectedTrucker(trucker)">
                                                        <td>{{ trucker.id }}</td>
                                                        <td>{{ trucker.name }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END TRUCKER ID MODAL -->

                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Date Hired:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="date" class="form-control" id="date" name="Date">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Years of Service 3PL:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" placeholder="" id="yearsOfService"
                                name="yearsOfService" [(ngModel)]="addDriverRequest.yearsOfService">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Driver is banned?:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <div class="dropdown w-100">
                                <button
                                    class="btn border btn-md dropdown-toggle d-flex justify-content-between align-items-center w-100"
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>Select</span>
                                    <span class="dropdown-toggle-split"></span>
                                </button>
                                <ul class="dropdown-menu w-100">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--END 3PL INFORMATION -->
        </div>
    </div>
    <div class="container-fluid mt-3 px-xl-5">
        <!--START DOCUMENT INFORMATION -->
        <div class="row d-flex">
            <h5 class="mb-3">Document Information</h5>
            <div class="left-content col-12 col-lg-6 col-xl-6">
                <div class="text-end">
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">Biodata:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle" id="Biodata"
                                name="Biodata" [(ngModel)]="addDriverRequest.hasBiodata">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">NBI Clearance:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle" id="NBI" name="NBI"
                                [(ngModel)]="addDriverRequest.hasNBIClearance">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">Barangay Clearance:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                id="hasBarangayClearance" name="hasBarangayClearance"
                                [(ngModel)]="addDriverRequest.hasBarangayClearance">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">Police Clearance:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle" id="hasPoliceClearance"
                                name="hasPoliceClearance" [(ngModel)]="addDriverRequest.hasPoliceClearance">
                        </div>
                    </div>


                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">APEDate:</label>
                        </div>
                        <div class="col col-lg-8">
                            <input type="date" class="form-control" id="apeDate" name="apeDate"
                                [(ngModel)]="addDriverRequest.apeDate">
                        </div>
                    </div>
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3 col-xl-3">
                            <label class="col-form-label">Drug Test Date:</label>
                        </div>
                        <div class="col col-lg-8 text-start ">
                            <input type="date" class="form-control" id="date" id="drugTestDate" name="drugTestDate"
                                [(ngModel)]="addDriverRequest.drugTestDate">
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-content col-12 col-lg-6 col-xl-6 mb-3">
                <div class="text-end">
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-4 col-xl-3">
                            <label class="col-form-label">Annual Physical Examination:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" id="" name="" value="">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-4 col-xl-3">
                            <label class="col-form-label">Pre-Defensive Drive Course:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                id="hasPreDefensiveDriveCourse" name="hasPreDefensiveDriveCourse"
                                [(ngModel)]="addDriverRequest.hasPreDefensiveDriveCourse">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-4 col-xl-3">
                            <label class="col-form-label">Defensive Drive Course:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                id="hasDefensiveDriveCourse" name="hasDefensiveDriveCourse"
                                [(ngModel)]="addDriverRequest.hasDefensiveDriveCourse">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-4 col-xl-3">
                            <label class="col-form-label">Safety, Health, Environment:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle" id="hasSHE"
                                name="hasSHE" [(ngModel)]="addDriverRequest.hasSHE">
                        </div>
                    </div>
                    <div [ngClass]="docInputStyle">
                        <div class="col-12 col-lg-4 col-xl-3">
                            <label class="col-form-label">Good Warehouse Practice:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-6 text-start px-0 px-md-4">
                            <input type="checkbox" id="" name="" value="" class="rounded-circle"
                                id="hasGoodWarehousePractice" name="hasGoodWarehousePractice"
                                [(ngModel)]="addDriverRequest.hasGoodWarehousePractice">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--END DOCUMENT INFORMATION -->
        <div class="border-top w-100 d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-primary mt-2" (click)="addDriver()">Submit</button>
        </div>
    </div>
    <!-- END FORM -->
</div>