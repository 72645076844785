import { Component, ViewChild } from '@angular/core';
import { Driver } from '../../../model/driver.model';
import { DriverService } from '../../../service/driver.service';
import { Router } from '@angular/router';
import { Trucker } from '../../../model/trucker.model';
import { ToastComponent } from '../../toast/toast.component';
import { ColDef, GridApi, CellContextMenuEvent } from 'ag-grid-community';
import { TruckerService } from '../../../service/trucker.service';
declare var bootstrap: any;

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrl: './add-driver.component.css'
})
export class AddDriverComponent {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3'
  docInputStyle = 'row align-items-center d-flex  flex-md-row mb-3'
  isValidStyle: boolean = false

  rowData: any[] = [];
  drivers: Driver[] = [];
  searchDriver: string = ''
  searchTrucker: string = ''
  filteredTrucker: Trucker[] = [];
  selectedDriver: any[] = [];
  filteredDriver: Driver[] = [];
  trucker: Trucker[] = [];

  // employmentStatuses: string[] = ['Active', 'Inactive', 'Suspended', 'Terminated'];
  employmentStatuses: boolean[] = [true, false]
  licenseTypeIds: string[] = ['Non-professional', 'Professional']
  licenseRestriction: number[] = [1, 2];
  selectedEmploymentStatus: boolean | undefined;
  selectedlicenseTypeId: string | undefined;
  selectedLicenseRestriction: number | undefined;
  truckerIdModalOpen: boolean = false
  isSubmited: boolean = false

  constructor(private driverService: DriverService, private router: Router, private truckerService: TruckerService) {

  }

  selectEmploymentStatus(status: boolean) {
    this.selectedEmploymentStatus = status;
  }
  selectlicenseTypeId(status: string) {
    this.selectedlicenseTypeId = status;
  }
  selectLicenseRestriction(status: number) {
    this.selectedLicenseRestriction = status
  }

  addDriverRequest: Driver = {
    // id: '',
    firstName: '',
    lastName: '',
    middleName: '',
    nickName: '',
    contactNumber: '',
    yearsOfService: '',
    truckerId: 0,
    licenseNo: '',
    licenseExpiry: '',
    licenseType: '',
    fileNumber: '',
    filePath: '',
    licenseNoFilePath: '',
    createdDate: new Date(),
    // lastUpdatedDate:'',
    createdByUserId: 0,
    lastUpdatedByUserId: 0,
    status: true,
    driverBlacklistTypeId: 0,
    hasBiodata: false,
    hasNBIClearance: false,
    hasBarangayClearance: false,
    hasPoliceClearance: false,
    hasPreDefensiveDriveCourse: false,
    hasDefensiveDriveCourse: false,
    hasSHE: false,
    hasGoodWarehousePractice: false,
    apeDate: '',
    drugTestDate: ''
  }
  addTruckerRequest: Trucker = {
    name: ''
  }

  columnDefs: ColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Company Name' },
  ];

  //START TOAST MESSAGE
  showToast(message: string, header: string, status: string) {
    this.toastComponent.message = message;
    this.toastComponent.header = header;
    this.toastComponent.status = status;
    this.toastComponent.showToast();
  }
  //END TOAST MESSAGE

  // START ADD DRIVER FUNTION
  addDriver() {
    this.isSubmited = true
    if (this.addDriverRequest.truckerId === 0 || this.addDriverRequest.truckerId === null || this.addDriverRequest.truckerId === undefined) {
      this.showToast('Please Fill-out all required entry, Thank you', 'Failed', 'danger');
    }
    else {
      this.showToast('Driver Successfully Added', 'Successful', 'success');
      this.driverService.addDriver(this.addDriverRequest)
        .subscribe({
          next: (res) => {
            // res.lastUpdatedDate = this.formatDateTime(new Date());
            setTimeout(() => {
              this.router.navigate(['driver'])
            }, 500);
          }
        });
    }
  }
  // END ADD DRIVER FUNTION

  // START TruckerName MODAL
  openModal() {
    const modalElement = document.getElementById('truckerModal');
    const modalInstance = new bootstrap.Modal(modalElement);
    modalInstance.show();
    this.getTrucker()
  }

  closeModal() {
    const modalElement = document.getElementById('truckerModal');
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }
  // END TruckerName MODAL

  // START GET ALL Trucker ID for Company Name
  getTrucker() {
    this.truckerService.getAllTruckers().subscribe({
      next: (data) => {
        this.trucker = data
        this.rowData = data;
      }
    })
  }
  // END GET ALL Trucker ID for Company Name

  onSearch() {
    if (this.searchDriver) {
      this.filteredDriver = this.drivers.filter(driver =>
        driver.firstName?.toLowerCase().includes(this.searchDriver.toLowerCase())
      );

    } else {
      this.filteredDriver = this.drivers;
    }
  }

  onkeypress(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.onSearch()
    }
  }

  selectedTrucker(trucker: any) {
    this.addDriverRequest.truckerId = trucker.id;
    this.addTruckerRequest.name = trucker.name;
    this.closeModal()
  }
}
