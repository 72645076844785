import { Component, ViewChild } from '@angular/core';
import { ToastComponent } from '../toast/toast.component';
@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.css']
})
export class ToastContainerComponent {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(message: string, header: string, status: string, delay: number = 5000) {
    if (this.toastComponent) {
      this.toastComponent.message = message;
      this.toastComponent.header = header;
      this.toastComponent.status = status;
      this.toastComponent.delay = delay;
      this.toastComponent.showToast();
    }
  }
}
