import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() message: string = '';
  @Input() header: string = '';
  @Input() status: string = '';
  @Input() delay: number = 5000;

  show: boolean = false;
  private routerSubscription: Subscription = new Subscription();

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.show = false;  
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  showToast() {
    this.show = true;
    // setTimeout(() => this.show = false, this.delay);
  }
}
