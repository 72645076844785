import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DriverService } from '../../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { ToastComponent } from '../../toast/toast.component';
import { TruckerService } from '../../../service/trucker.service';
import { TokenService } from '../../../service/token.service';
import { userAccess } from '../../../model/userAccess.model';
declare var bootstrap: any;

@Component({
    selector: 'app-add-edit-driver',
    templateUrl: './add-edit-driver.component.html',
    styleUrls: ['./add-edit-driver.component.css']
})
export class AddEditDriverComponent implements OnInit {
    @ViewChild(ToastComponent) toastComponent!: ToastComponent;

    constructor(
        private driverService: DriverService,
        private truckerService: TruckerService,
        private route: ActivatedRoute,
        private router: Router,
        private tokenService: TokenService
    ) {

    }

    inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3';
    docInputStyle = 'row align-items-center d-flex flex-md-row mb-3';
    displayDropdown = false;
    contextMenuPosition = { x: '0px', y: '0px' };
    driverId: string | null = null;
    truckerId: string | null = null;
    isEditMode: boolean = false;
    searchTrucker: string = ''
    clickedRowData: any = null;
    selectedBlacklistType: string = 'Select';
    rowData: any[] = [];
    access: any[] = [];
    userHasEditAccess: boolean = false;
    userHasCreateAccess: boolean = false;
    userHasViewAccess: boolean = false;
    userHasDeleteAccess: boolean = false;
    Token: string = '';
    blacklist: any[] = [];
    idFromDriverList: string | null = "";
    truckerData: any[] = [];
    employmentStatusOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];
    employmentStatusVisible: boolean = false

    fieldDisabled: boolean = false;
    isEdit: boolean = false;

    columnDefs: ColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Company Name' },
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
    };

    driverForm: FormGroup = new FormGroup({
        firstName: new FormControl({ value: '', disabled: false }),
        lastName: new FormControl({ value: '', disabled: false }),
        middleName: new FormControl({ value: '', disabled: false }),
        nickName: new FormControl({ value: '', disabled: false }),
        contactNumber: new FormControl({ value: '', disabled: false }),
        yearsOfService: new FormControl({ value: '', disabled: false }),
        truckerId: new FormControl({ value: '', disabled: false }),
        truckerName: new FormControl({ value: '', disabled: false }),
        licenseNo: new FormControl({ value: '', disabled: false }),
        licenseExpiry: new FormControl({ value: '', disabled: false }),
        licenseType: new FormControl({ value: '', disabled: false }),
        fileNumber: new FormControl({ value: '', disabled: false }),
        filePath: new FormControl({ value: '', disabled: false }),
        licenseNoFilePath: new FormControl({ value: '', disabled: false }),
        createdDate: new FormControl({ value: '', disabled: false }),
        lastUpdatedDate: new FormControl({ value: '', disabled: false }),
        status: new FormControl({ value: true, disabled: false }),
        driverBlacklistTypeId: new FormControl({ value: '', disabled: false }),
        hasBiodata: new FormControl({ value: false, disabled: false }),
        hasNBIClearance: new FormControl({ value: false, disabled: false }),
        hasBarangayClearance: new FormControl({ value: false, disabled: false }),
        hasPoliceClearance: new FormControl({ value: false, disabled: false }),
        hasPreDefensiveDriveCourse: new FormControl({ value: false, disabled: false }),
        hasDefensiveDriveCourse: new FormControl({ value: false, disabled: false }),
        hasSHE: new FormControl({ value: false, disabled: false }),
        hasGoodWarehousePractice: new FormControl({ value: false, disabled: false }),
        dateHire: new FormControl({ value: '', disabled: false }),
        apeDate: new FormControl({ value: '', disabled: false }),
        drugTestDate: new FormControl({ value: '', disabled: false }),
        createdByUserId: new FormControl({ value: '', disabled: false }),
        lastUpdatedByUserId: new FormControl({ value: '', disabled: false }),
    });

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['config']) {
                try {
                    // Decode and parse the 'config' from query params
                    const config = JSON.parse(decodeURIComponent(params['config']));
                    console.log('Token stored:', config.Id);
                    console.log('Menuid stored:', 'menuId:', params['menuId']);
                    // Set the token using the token service
                    this.tokenService.setToken(config.Id);
                    const menuId = params['menuId']
                    // Now that the token is set, proceed to call getAccess with the token
                    this.tokenService.getAccess(config.Id, menuId).subscribe({
                        next: (data: userAccess[]) => {
                            console.log('Access data received:', data);
                            this.access = data;
                            this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                            this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                            this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                            this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
                        },
                        error: (err) => console.error('Error loading access data:', err),
                    });
                } catch (error) {
                    console.error('Error parsing config JSON:', error);
                }
            } else {
                console.warn("Config parameter missing from URL query params.");
            }
        });


        this.route.paramMap.subscribe(params => {
            const state = history.state as { fieldDisabled?: boolean };
            this.fieldDisabled = state?.fieldDisabled ?? false;
            // Iterate over each control in the FormGroup
            Object.keys(this.driverForm.controls).forEach(controlName => {
                const control = this.driverForm.get(controlName);
                if (control) {
                    if (this.fieldDisabled) {
                        control.disable({ onlySelf: true });
                    } else {
                        control.enable({ onlySelf: true });
                    }
                }
            });
        });
        const isPageRefreshed = sessionStorage.getItem('pageRefreshed') === 'true';

        if (isPageRefreshed) {
            this.resetForm();
            sessionStorage.removeItem('pageRefreshed');
        } else {
            const state = history.state as { fieldDisabled?: boolean, driverId?: string };
            this.fieldDisabled = state?.fieldDisabled ?? false;
            this.idFromDriverList = state?.driverId || null;
            this.isEdit = this.fieldDisabled
            this.driverId = this.route.snapshot.paramMap.get('id');
            this.truckerService.getAllTruckers().subscribe({
                next: (data) => {
                    this.truckerData = data;
                    this.rowData = data;
                    // Load driver data after trucker data has been loaded
                    if (this.idFromDriverList) {
                        this.isEditMode = true;
                        this.driverService.getDriverById(this.idFromDriverList).subscribe(driver => {
                            // Find the trucker by ID
                            const trucker = this.truckerData.find(t => t.id === driver.truckerId);
                            // Update the form values
                            this.driverForm.patchValue({
                                truckerName: trucker ? trucker.name : null, // Use the trucker's name if found
                                ...driver,
                                // Convert to YYYY-MM-DD format to display in the edit
                                apeDate: driver.apeDate ? new Date(driver.apeDate).toISOString().split('T')[0] : null,
                                drugTestDate: driver.drugTestDate ? new Date(driver.drugTestDate).toISOString().split('T')[0] : null,
                                licenseExpiry: driver.licenseExpiry ? new Date(driver.licenseExpiry).toISOString().split('T')[0] : null,
                            });
                            const blacklistType = this.blacklist.find(b => b.id === driver.driverBlacklistTypeId);
                            this.selectedBlacklistType = blacklistType ? blacklistType.description : 'Select';
                        });
                    }
                }
            });
        }

        this.driverService.getAllBlacklist().subscribe({
            next: (data) => {
                this.blacklist = data
            }
        })
    }
    
    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload(event: Event): void {
        sessionStorage.setItem('pageRefreshed', 'true');
    }
    resetForm(): void {
        this.driverForm.reset();
        this.driverForm.patchValue({
        })
        this.isEditMode = true;
        this.toggleFormControls(false);
        this.truckerService.getAllTruckers().subscribe({
            next: (data) => {
                this.truckerData = data;
                this.rowData = data;
            }
        })
    }
    saveDriver() {
        // START CHECK IF FIELDS ARE EMPTY AND SHOW TOAST
        const truckerId = this.driverForm.get('truckerId')?.value;
        const firstName = this.driverForm.get('firstName')?.value;
        const lastName = this.driverForm.get('lastName')?.value;
        if (truckerId === 0 || truckerId === null || truckerId === undefined || truckerId === '' || firstName === '' || lastName === '') {
            this.showToast('Please Fill-out all empty fields, Thank you', 'Failed', 'danger');
            window.scroll(0, 0);
            return;
        }
        // END CHECK IF FIELDS ARE EMPTY AND SHOW TOAST

        const createdUserById = this.access?.[0]?.user || null;
        //START UPDATE DRIVER
        if (this.isEditMode && this.idFromDriverList) {
            console.log(this.idFromDriverList)
            this.driverForm.patchValue({ lastUpdatedDate: new Date(), lastUpdatedByUserId: createdUserById });
            this.driverService.updateDriver(this.idFromDriverList, this.driverForm.value).subscribe({
                next: () => {
                    // this.router.navigate(['/driver']); // Redirect to the list or detail page
                    this.showToast('Updated Driver, Thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
        }
        //END UPDATE DRIVER
        //START ADD NEW DRIVER
        else {
            const currentDate = new Date();
            this.driverForm.patchValue({
                createdDate: currentDate,
                lastUpdatedDate: currentDate,
                createdByUserId: createdUserById,
            });
            this.driverService.addDriver(this.driverForm.value).subscribe({
                next: () => {
                    this.showToast('Added New Driver, Thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
        }
        //END ADD NEW DRIVER
    }

    //START MODAL FOR COMPANY NAME
    openModal() {
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = new bootstrap.Modal(modalElement);
        modalInstance.show();
    }

    closeModal() {
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    }

    rowClicked(event: any): void {
        this.clickedRowData = event.data;
        this.driverForm.get('truckerId')?.setValue(event.data.id);
        this.driverForm.get('truckerName')?.setValue(event.data.name);
        this.closeModal();
    }
    //END MODAL FOR COMPANY NAME

    // START EMPLOYEE STATUS DROPDOWN
    getEmploymentStatusLabel(): string {
        const statusValue = this.driverForm?.get('status')?.value;
        const selectedOption = this.employmentStatusOptions?.find(option => option.value === statusValue);
        return selectedOption ? selectedOption.label : 'Select';
    }

    selectEmploymentStatus(value: any): void {
        this.driverForm.get('status')?.setValue(value);
    }
    // END EMPLOYEE STATUS DROPDOWN

    // START TOAST
    showToast(message: string, header: string, status: string) {
        this.toastComponent.message = message;
        this.toastComponent.header = header;
        this.toastComponent.status = status;
        this.toastComponent.showToast();
    }
    // END TOAST

    //START CONTEXT FOR FORM
    onCancel() {
        this.router.navigate(['/driver/list']);
    }

    onRightClick(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition = {
            x: `${event.pageX}px`,
            y: `${event.pageY}px`
        };
        this.displayDropdown = true;
    }

    onFindDriver() {
        this.router.navigate(['/driver/list']);
        this.displayDropdown = false;
    }

    onCreateDriver() {
        this.router.navigate(['/driver/form'])
        this.toggleFormControls(false);
        this.driverForm.reset({
            firstName: '',
            lastName: '',
            middleName: '',
            nickName: '',
            contactNumber: '',
            yearsOfService: '',
            truckerId: '',
            licenseNo: '',
            licenseExpiry: '',
            licenseType: '',
            fileNumber: '',
            filePath: '',
            licenseNoFilePath: '',
            createdDate: '',
            lastUpdatedDate: '',
            status: true,
            driverBlacklistTypeId: '',
            hasBiodata: false,
            hasNBIClearance: false,
            hasBarangayClearance: false,
            hasPoliceClearance: false,
            hasPreDefensiveDriveCourse: false,
            hasDefensiveDriveCourse: false,
            hasSHE: false,
            hasGoodWarehousePractice: false,
            apeDate: '',
            drugTestDate: '',
        })
        this.displayDropdown = false;
    }


    toggleFormControls(disable: boolean): void {
        Object.keys(this.driverForm.controls).forEach(controlName => {
            const control = this.driverForm.get(controlName);
            if (control) {
                if (disable) {
                    control.disable({ onlySelf: true });
                } else {
                    control.enable({ onlySelf: true });
                }
            }
        });
    }
    onEditDriver() {
        this.displayDropdown = false;
        this.toggleFormControls(false);
    }
    //END CONTEXT FOR FORM

    // onCellContextMenu(event: CellContextMenuEvent) {
    //     if (event && event.event) {
    //         this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
    //         this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
    //         this.displayDropdown = true;
    //         event.event.preventDefault();
    //     }
    // }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        this.displayDropdown = false;
    }
    onBlackListTypeSelectItem(item: any): void {
        this.selectedBlacklistType = item.description;
        this.driverForm.patchValue({ driverBlacklistTypeId: item.id });
    }
}
