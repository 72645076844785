<form [formGroup]="driverBlacklistForm" (submit)="saveBlacklist()">
    <div class="container-fluid border p-3">
        <div class="container-fluid mt-3 px-xl-5">
            <div class="row d-flex justify-content-between">
                <div class="container-fluid p-2">
                    <h5 class="mb-3">Create Blacklist Type:</h5>
                </div>
                <div class="text-start text-lg-end">
                    
                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Description:</label>
                        </div>
                        <div class="col-12 col-lg-8">
                            <input type="text" class="is-invalid form-control" placeholder="" id="description"
                                name="description" formControlName="description">
                        </div>
                    </div>

                    <div [ngClass]="inputStyle">
                        <div class="col-12 col-lg-3">
                            <label class="col-form-label">Status:</label>
                        </div>
                        <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                            <input type="checkbox" class="rounded-circle" value="" placeholder="" id="status"
                                name="status" formControlName="status">
                        </div>
                        
                    </div>
                        <div class="d-flex justify-content-end mb-2">
                            <app-toast #toastComponent></app-toast>
                        </div>
                        <div class="p-2">
                            <button type="button" class="btn btn-sm btn-primary" type="submit">Submit</button>
                        </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>