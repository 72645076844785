import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { TruckMovementHistory } from '../model/truckMovementHistory.model';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class TruckmovementhistoryService {
  baseApiUrl: string = environment.baseApiUrl;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {}

  getAllTruckMovementHistoryWithTruckId(truckId: string, jwt: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwt}` // Fixed typo here
    });
    return this.http.get<TruckMovementHistory[]>(`${this.baseApiUrl}/api/truckmovementhistory/${truckId}`, { headers });
  }
}
