import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { Trucker } from '../model/trucker.model';

@Injectable({
  providedIn: 'root'
})
export class TruckerService {

  baseApiUrl: string = environment.baseApiUrl


  constructor(private http: HttpClient) { }
 
  getAllTruckers(): Observable<Trucker[]> {
    return this.http.get<Trucker[]>(this.baseApiUrl + "/api/Trucker")
  }
}
