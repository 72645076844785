<app-layout title="Blacklist" [tabPages]=" ['Information', 'List' ]" [(selectedTabIndex)]="currentTabIndex"
    (tabChange)="onTabChange($event)">
    <div tab1Content id="content1">
        <form [formGroup]="driverBlacklistForm" (submit)="saveBlacklist()" (contextmenu)="onRightClick($event)">
            <div class="container-fluid border p-3">
                <div class="container-fluid mt-3 px-xl-5">
                    <div *ngIf="isNotif" class="alert alert-{{notif.status}} d-flex justify-content-between" role="alert">
                        <div class="d-flex justify-content-end mb-2">
                          <div>
                            <strong class="me-auto">{{ notif.header }}</strong>
                            {{ notif.message }}
                          </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="isNotif = false"></button>
                      </div>
                      
                    <!-- <div *ngIf="isAdded" class="alert alert-{{notif.status}} d-flex justify-content-between"
                        role="alert">
                        <div class="d-flex justify-content-end mb-2">
                            <div>
                                <strong class="me-auto">{{ notif.header }}</strong>
                                {{ notif.message }}
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> -->

                    <div class="row d-flex justify-content-between">
                        <div class="left-content col-12 col-lg-6 col-xl-6 mb-3">
                            <!-- <app-toast *ngIf="isUpdated" #toastComponent></app-toast>
                            <app-toast *ngIf="isAdded" #toastComponent></app-toast> -->

                            <div [ngClass]="inputStyle">
                                <div class="col-12 col-lg-3">
                                    <label class="col-form-label">description:</label>
                                </div>
                                <div class="col-12 col-lg-8">
                                    <input type="text" [ngClass]="driverBlacklistForm.get('description')?.value ? 'form-control' : 'is-invalid form-control'" placeholder="" id="description"
                                        name="description" formControlName="description">
                                </div>
                            </div>

                            <div [ngClass]="inputStyle" *ngIf="status">
                                <div class="col-12 col-lg-3">
                                    <label class="col-form-label">Status:</label>
                                </div>
                                <div class="col-1 col-lg-6 col-xl-7 px-0 px-md-4 text-start">
                                    <input type="checkbox" class="rounded-circle" value="" placeholder="" id="status"
                                        name="status" formControlName="status">
                                </div>
                            </div>

                            <div>
                            </div>
                        </div>
                        <div class="border-top w-100 d-flex justify-content-end gap-2">
                            <button type="button" class="btn btn-sm btn-primary mt-2" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="displayDropdown" class="dropdown-menu"
            [ngStyle]="{'top': contextMenuPosition.y, 'left': contextMenuPosition.x, 'display': displayDropdown ? 'block' : 'none'}">
            <button class="dropdown-item" (click)="onFindBlacklist()">Find</button>
            <button class="dropdown-item" [disabled]="!userHasEditAccess" (click)="onRightEditBlacklist()">Edit</button>
            <button class="dropdown-item" [disabled]="!userHasCreateAccess" (click)="onRightBlacklist()">Create</button>
        </div>
    </div>
    <div tab2Content id="content2">
        <div class="container-fluid border p-2 px-4" (contextmenu)="onRightClick($event)">
            <div class="accordion mb-2" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button fw-medium fs-6" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Filter Criteria
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <h2 *ngIf="dateError" class="bg-danger text-white">Error</h2>
                            <form [formGroup]="searchForm">
                                <div *ngIf="criteriaList.length > 0">
                                    <ul class="px-0 mb-2">
                                        <li *ngFor="let criteria of criteriaList; let i = index"
                                            class=" d-flex gap-4 align-items-center mb-1">
                                            <button type="button"
                                                class="btn btn-xs btn-danger rounded-0 criteria-close-btn d-flex align-items-center justify-content-center"
                                                (click)="
                                            removeCriteria(i)"><i class="bi bi-x-lg"></i></button>
                                            <table>
                                                <tr>
                                                    <td [ngStyle]="{ 'width': '90px'}">
                                                        <span>
                                                            {{ criteria.selectedOption === 'description' ? 'description'
                                                            :
                                                            criteria.selectedOption === 'createdDate' ? 'Created Date' :
                                                            ''
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        <span>
                                                            {{ criteria.selectedOption === 'description' ?
                                                            criteria.descriptionFrom + ' - ' +
                                                            criteria.descriptionTo :criteria.selectedOption ===
                                                            'createdDate' ? (criteria.startDate| date:'MM/dd/yyyy') + '
                                                            -
                                                            ' +
                                                            (criteria.endDate | date:'MM/dd/yyyy') :''
                                                            }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>

                                        </li>
                                    </ul>
                                </div>


                                <div class="row">
                                    <div class="col-md-3">
                                        <select id="selectedOption" formControlName="selectedOption"
                                            class="form-select rounded-0" (change)="selectFilter($event)">
                                            <option value="description">description</option>
                                            <option value="createdDate">Created Date</option>
                                        </select>
                                    </div>
                                    <div *ngIf="searchForm.get('selectedOption')?.value === 'description'"
                                        class="col-md-3">
                                        <input id="description" class="form-control" type="text"
                                            formControlName="descriptionFrom" />
                                    </div>
                                    <div *ngIf="searchForm.get('selectedOption')?.value === 'description'"
                                        class="col-md-3">
                                        <input id="description" class="form-control" type="text"
                                            formControlName="descriptionTo" />
                                    </div>
                                    <div *ngIf="searchForm.get('selectedOption')?.value === 'createdDate'"
                                        class="d-flex gap-2 col-md-5">
                                        <input id="startDate" class="form-control" type="date"
                                            formControlName="startDate" />
                                        <input id="createEndDate" class="form-control" type="date"
                                            formControlName="endDate" />
                                    </div>
                                    <div *ngIf="searchForm.get('selectedOption')?.value === 'lastUpdatedDate'"
                                        class="d-flex gap-2 col-md-5">
                                        <input id="updateStartDate" class="form-control" type="date"
                                            formControlName="updateStartDate" />
                                        <input id="updateEndDate" class="form-control" type="date"
                                            formControlName="updateEndDate" />
                                    </div>
                                    <div *ngIf="searchForm.get('selectedOption')?.value === 'isActive'"
                                        class="d-flex col-md-1">
                                        <select id="activeStatus" class="form-select rounded-0"
                                            formControlName="status">
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 d-flex gap-2">
                                        <button type="button" class="btn btn-sm btn-primary" (click)="addCriteria()">Add
                                            Criteria</button>
                                        <button type="submit" class="btn btn-sm btn-primary"
                                            [disabled]="criteriaList.length === 0" (click)="onSearch()">Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- START TABLE -->

            <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="filteredRowData"
                [columnDefs]="columnDefs" (cellContextMenu)="onCellContextMenu($event)" [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)" (rowDoubleClicked)="onRowDoubleClicked($event)">
            </ag-grid-angular>

            <!-- END TABLE -->
            <!-- START RIGHTCLICK ACTION -->
            <div *ngIf="displayDropdown" class="dropdown-menu"
                [ngStyle]="{'top': contextMenuPosition.y, 'left': contextMenuPosition.x, 'display': displayDropdown ? 'block' : 'none'}">
                <button class="dropdown-item" [disabled]="!userHasEditAccess" (click)="onRightClickEdit()">Edit</button>
                <!-- <button class="dropdown-item" (click)="openAddBlacklist()">Add</button>
                <button class="dropdown-item" (click)="onEditBlacklist()">Edit</button> -->
            </div>

            <div class="p-2 align-items-center">
                <!-- <button type="button" class="btn btn-sm btn-primary col-md-1" (click)="openAddBlacklist()">Add
                    New</button> -->
            </div>
        </div>

    </div>
</app-layout>