import { Component, OnInit, HostListener, ViewChild, } from '@angular/core';
import { Driver } from '../../../model/driver.model';
import { DriverService } from '../../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FilterCriteria } from '../../../model/filterCriteria.model';
import { RowDoubleClickedEvent, GridApi } from 'ag-grid-community';
import { TruckerService } from '../../../service/trucker.service';
import { TokenService } from '../../../service/token.service';
import { ToastComponent } from '../../toast/toast.component';
import { ToastContainerComponent } from '../../toast-container/toast-container.component';
import { forkJoin } from 'rxjs';

declare var bootstrap: any;

@Component({
    selector: 'app-driverlist',
    templateUrl: './driverlist.component.html',
    styleUrls: ['./driverlist.component.css'],
})
export class DriverlistComponent implements OnInit {
    @ViewChild(ToastComponent) toastComponent!: ToastComponent;
    @ViewChild(ToastContainerComponent) multiToastContainer!: ToastContainerComponent;
    truckerColumnDefs: ColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Company Name' },
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
    };
    columnDefs: ColDef[] = [
        { field: 'firstName', headerName: 'First Name' },
        { field: 'middleName', headerName: 'Middle Name' },
        { field: 'lastName', headerName: 'Last Name' },
        { field: 'truckerName', headerName: 'Trucker Name' },
        { field: 'licenseNo', headerName: 'License No.' },
        { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
        { field: 'status', headerName: 'Status' },
    ];

    driverForm: FormGroup = new FormGroup({
        firstName: new FormControl({ value: '', disabled: false }),
        lastName: new FormControl({ value: '', disabled: false }),
        middleName: new FormControl({ value: '', disabled: false }),
        nickName: new FormControl({ value: '', disabled: false }),
        contactNumber: new FormControl({ value: '', disabled: false }),
        yearsOfService: new FormControl({ value: '', disabled: false }),
        truckerId: new FormControl({ value: '', disabled: false }),
        truckerName: new FormControl({ value: '', disabled: false }),
        licenseNo: new FormControl({ value: '', disabled: false }),
        licenseExpiry: new FormControl({ value: '', disabled: false }),
        licenseType: new FormControl({ value: '', disabled: false }),
        fileNumber: new FormControl({ value: '', disabled: false }),
        filePath: new FormControl({ value: '', disabled: false }),
        licenseNoFilePath: new FormControl({ value: '', disabled: false }),
        createdDate: new FormControl({ value: '', disabled: false }),
        lastUpdatedDate: new FormControl({ value: '', disabled: false }),
        status: new FormControl({ value: true, disabled: false }),
        driverBlacklistTypeId: new FormControl({ value: '', disabled: false }),
        hasBiodata: new FormControl({ value: false, disabled: false }),
        hasNbiclearance: new FormControl({ value: false, disabled: false }),
        hasBarangayClearance: new FormControl({ value: false, disabled: false }),
        hasPoliceClearance: new FormControl({ value: false, disabled: false }),
        hasPreDefensiveDriveCourse: new FormControl({ value: false, disabled: false }),
        hasDefensiveDriveCourse: new FormControl({ value: false, disabled: false }),
        hasShe: new FormControl({ value: false, disabled: false }),
        hasGoodWarehousePractice: new FormControl({ value: false, disabled: false }),
        dateHire: new FormControl({ value: '', disabled: false }),
        apedate: new FormControl({ value: '', disabled: false }),
        drugTestDate: new FormControl({ value: '', disabled: false }),
        createdByUserId: new FormControl({ value: '', disabled: false }),
        lastUpdatedByUserId: new FormControl({ value: '', disabled: false }),
    });

    inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3';
    docInputStyle = 'row align-items-center d-flex flex-md-row mb-3';
    displayDropdown = false;
    contextMenuPosition = { x: '0px', y: '0px' };
    driverId: string | null = null;
    truckerId: string | null = null;
    isEditMode: boolean = false;
    status: boolean = false;
    searchTrucker: string = ''
    clickedRowData: any = null;
    selectedBlacklistType: string = 'Select';
    rowData: any[] = [];
    access: any[] = [];
    userHasEditAccess: boolean = false;
    userHasCreateAccess: boolean = false;
    userHasViewAccess: boolean = false;
    userHasDeleteAccess: boolean = false;
    isNotif: boolean = false;
    notif: any = {
        header: '',
        status: '', // Can be 'success', 'error', 'info', etc.
        message: ''
    };
    myToken: string = '';
    jwt: string = '';
    menuId: number = 0;
    blacklist: any[] = [];
    idFromDriverList: string | null = "";
    truckerData: any[] = [];
    employmentStatusOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];
    employmentStatusVisible: boolean = false
    fieldDisabled: boolean = false;
    disableSubmit: boolean = false;
    isEdit: boolean = false;
    selectedTabIndex: number = 0;
    Token: string = '';
    filteredRowData: any[] = [];
    drivers: Driver[] = [];
    selectedDriver: Driver | null = null;
    selectedFilter: string | null = null;
    searchDriver: string = '';
    searchForm: FormGroup;
    criteriaList: FilterCriteria[] = [];
    isLoading: boolean = false
    title: string = 'Driver'
    constructor(
        private driverService: DriverService,
        private truckerService: TruckerService,
        private router: Router,
        private fb: FormBuilder,
        private tokenService: TokenService,
        private route: ActivatedRoute) {
        this.searchForm = this.fb.group({
            selectedOption: ['firstName'],
            firstNameFrom: [''],
            firstNameTo: [''],
            lastNameFrom: [''],
            lastNameTo: [''],
            middleNameFrom: [''],
            middleNameTo: [''],
            truckerNameFrom: [''],
            truckerNameTo: [''],
            licenseNoFrom: [''],
            licenseNoTo: [''],
            startDate: [''],
            endDate: ['']
        });
    }
    showToast(message: string, header: string, status: string) {
        this.toastComponent.message = message;
        this.toastComponent.header = header;
        this.toastComponent.status = status;
        this.toastComponent.showToast();
    }
    updateNotification(message: string, header: string, status: string,) {
        this.isNotif = true;
        this.notif.header = header;
        this.notif.status = status;
        this.notif.message = message;
    }
    setSelectedTab(index: number): void {
        this.selectedTabIndex = index;
    }
    ngOnInit(): void {

        this.route.queryParams.subscribe((params) => {
            if (params['config']) {
                try {
                    const config = JSON.parse(decodeURIComponent(params['config']));
                    const token = config?.Id; // Extract `authToken` from params
                    const menuId = params['menuId']; // Extract menu ID

                    if (!token) {
                        this.isNotif = true
                        this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
                        return;
                    }

                    if (!menuId) {
                        this.isNotif = true
                        this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
                        return;
                    }

                    this.myToken = token;
                    this.menuId = menuId;

                    // Fetch user access and JWT token from the backend
                    this.tokenService.getAccess(token, menuId).subscribe({
                        next: (data: any) => {
                            const jwtToken = data?.token; // JWT from backend
                            const authToken = token; // Auth Token from params
                            if (jwtToken) {
                                this.tokenService.setToken(jwtToken, authToken); // Store both tokens
                                this.jwt = jwtToken;
                            } else {
                                if (data) {
                                    this.isNotif = true
                                    this.updateNotification('to access please contact the developers', 'Failed', 'danger')
                                }
                            }

                            if (data?.access && Array.isArray(data.access)) {
                                this.access = data.access;
                                this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                                this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                                this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                                this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
                            } else {
                                // console.error('Expected access array but received:', data);
                                this.access = [];
                            }
                            // this.driverService.getAllBlacklist().subscribe({
                            //     next: (data) => {
                            //         this.blacklist = data
                            //     }, error: () => {
                            //         this.isNotif = true
                            //         this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
                            //     }
                            // })
                            this.driverService.getAllBlacklist().subscribe({
                                next: (data) => {
                                    this.blacklist = data;
                                },
                                error: (err) => {
                                    if (err.status === 401) {
                                        this.isNotif = true;
                                        this.updateNotification('Please contact the developers, thank you.', 'Failed', 'danger');
                                    } else {
                                        this.isNotif = true;
                                        this.updateNotification('Please contact the developers, thank you.', 'Failed', 'danger');
                                    }
                                },
                            });

                        },
                        error: (err) => {
                            this.isNotif = true
                            this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
                        },
                    });
                } catch (error) {
                    this.isNotif = true
                    this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
                }
            } else {
                this.tokenService.clearTokens()
                this.isNotif = true
                this.updateNotification('to access the page please contact the developers, thank you.', 'Failed', 'danger');
            }
        });
    }
    // START FETCH ALL DRIVER
    // fetchDriver(id: string | number) {
    //     if (!id) {
    //         console.error('Invalid driver ID.');
    //         return;
    //     }

    //     // Convert id to string to match the expected parameter type
    //     const driverId = id.toString();

    //     this.isEditMode = true;
    //     this.driverService.getDriverById(driverId).subscribe(driver => {
    //         if (!driver) {
    //             console.error('Driver not found.');
    //             return;
    //         }
    //         const trucker = this.truckerData.find(t => t.id === driver.truckerId);
    //         console.log(trucker)
    //         this.driverForm.patchValue({
    //             truckerName: trucker ? trucker.name : null, // Use trucker's name if found
    //             ...driver,
    //             apedate: driver.apedate ? new Date(driver.apedate).toISOString().split('T')[0] : null,
    //             drugTestDate: driver.drugTestDate ? new Date(driver.drugTestDate).toISOString().split('T')[0] : null,
    //             licenseExpiry: driver.licenseExpiry ? new Date(driver.licenseExpiry).toISOString().split('T')[0] : null,
    //         });

    //         const blacklistType = this.blacklist.find(b => b.id === driver.driverBlacklistTypeId);
    //         this.selectedBlacklistType = blacklistType ? blacklistType.description : 'Select';
    //     }, error => {
    //         console.error('Error fetching driver:', error);
    //     });
    // }
    loadTruckerData() {
        return this.truckerService.getAllTruckers();
    }

    fetchDriver(id: string | number) {
        if (!id && this.jwt === '') {
            this.isNotif = true
            this.updateNotification('to access the page please contact the developers, thank you.', 'Failed', 'danger');
            return;
        }

        forkJoin({
            driver: this.driverService.getDriverById(id.toString()),
            truckerData: this.loadTruckerData()
        }).subscribe(({ driver, truckerData }) => {
            if (!driver) {
                this.isNotif = true
                this.updateNotification('Driver not found.', 'Failed', 'danger')
                return;
            }

            const trucker = truckerData.find(t => t.id === driver.truckerId);

            this.driverForm.patchValue({
                truckerName: trucker ? trucker.name : null,
                ...driver,
                apedate: driver.apedate ? new Date(driver.apedate).toISOString().split('T')[0] : null,
                drugTestDate: driver.drugTestDate ? new Date(driver.drugTestDate).toISOString().split('T')[0] : null,
                licenseExpiry: driver.licenseExpiry ? new Date(driver.licenseExpiry).toISOString().split('T')[0] : null,
            });
            const blacklistType = this.blacklist.find(b => b.id === driver.driverBlacklistTypeId);
            this.selectedBlacklistType = blacklistType ? blacklistType.description : 'Select';
        }, error => {
            this.isNotif = true
            this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
        });
    }
    //   if (!id && this.jwt === '') {
    //     console.log('Invalid driver ID.');
    //     return;
    //   }
    //   forkJoin({
    //     truck: this.truckService.getTruckById(id.toString()),
    //     truckerData: this.loadTruckerData(),
    //     truckTypeData: this.loadTruckTypeData()
    //   }).subscribe(({ truck, truckerData, truckTypeData }) => {
    //     if (!truck) {
    //       console.log('truck not found.');
    //       return;
    //     }
    //     const trucker = this.truckerData.find(t => t.id === truck.truckerId);
    //     const truckType = this.truckTypeData.find(t => t.id === truck.truckTypeId);

    //     this.truckForm.patchValue({
    //       truckerName: trucker ? trucker.name : null,
    //       truckTypeName: truckType ? truckType.type : null,
    //       ...truck,
    //       crDate: truck.crDate ? new Date(truck.crDate).toISOString().split('T')[0] : null,
    //       orDate: truck.orDate ? new Date(truck.orDate).toISOString().split('T')[0] : null,
    //       acquisitionDate: truck.acquisitionDate ? new Date(truck.acquisitionDate).toISOString().split('T')[0] : null,
    //       emissionDate: truck.emissionDate ? new Date(truck.emissionDate).toISOString().split('T')[0] : null,
    //       insuranceExpiryDate: truck.insuranceExpiryDate ? new Date(truck.insuranceExpiryDate).toISOString().split('T')[0] : null,
    //       ltfrbExpirationDate: truck.ltfrbExpirationDate ? new Date(truck.ltfrbExpirationDate).toISOString().split('T')[0] : null,
    //     });
    //   })
    // }
    // END FETCH ALL DRIVER

    //STAR SAVE DRIVER

    selectedDriverId: string | null = null; // Store the selected driver ID

    saveDriver() {

        const truckerId = this.driverForm.get('truckerId')?.value;
        const firstName = this.driverForm.get('firstName')?.value;
        const lastName = this.driverForm.get('lastName')?.value;
        const jwt = this.tokenService.getJwt();
        if (!jwt) {
            this.isNotif = true
            this.updateNotification("Please contact developers, thank you.", 'Failed', 'danger');
            window.scroll(0, 0);
            return;
        }
        if (truckerId === 0 || !truckerId || !firstName || !lastName) {
            this.isNotif = true
            this.updateNotification('Please Fill-out all empty fields, thank you.', 'Failed', 'danger');
            window.scroll(0, 0);
            return;
        }

        const createdUserById = this.access?.[0]?.user || null;
        const currentDate = new Date();
        if (this.selectedDriverId) {
            this.driverForm.patchValue({ lastUpdatedDate: new Date(), lastUpdatedByUserId: createdUserById });
            this.driverService.updateDriver(this.selectedDriverId, this.driverForm.value).subscribe({
                next: () => {
                    this.isNotif = true
                    this.updateNotification('Updated Driver, Thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                },
                error: (error) => {
                    this.isNotif = true
                    this.updateNotification('to update driver', 'Failed', 'danger')
                },
            });
        } else {
            this.driverForm.patchValue({
                createdDate: currentDate,
                createdByUserId: createdUserById,
            });
            const formValue = { ...this.driverForm.value }; // Create a shallow copy
            delete formValue.lastUpdatedByUserId; // Remove `lastUpdatedByUserId`
            this.driverService.addDriver(formValue).subscribe({
                next: () => {
                    this.isNotif = true
                    this.updateNotification('Added new driver, thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                },
                error: (error) => {
                    window.scroll(0, 0);
                    this.isNotif = true
                    this.updateNotification('to add new driver', 'Failed', 'danger');
                },
            });
        }
    }

    //END SAVE DRIVER
    // START DATE FORMAT
    dateFormatter(params: any): string {
        const date = new Date(params.value);
        if (!isNaN(date.getTime())) {
            return date.toLocaleDateString();
        }
        return params.value;
    }
    // END DATE FORMAT

    // START RIGHT CLICK CONTEXT MENU
    onRightClick(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition.x = `${event.clientX}px`;
        this.contextMenuPosition.y = `${event.clientY}px`;
        this.displayDropdown = true;
    }

    onEditDriver() {
        if (this.selectedDriver && this.jwt != '') {
            this.disableSubmit = false
            this.isEditMode = true;
            const driverString = this.selectedDriver?.toString();
            // const driverId = this.selectedDriver.id ?? ""; // Extract `id` as a string
            this.selectedDriverId = driverString; // Assign the string `id` to the shared property
            this.fetchDriver(driverString);
            this.currentTabIndex = 0; // Switch to Tab 1
            this.toggleFormControls(false);
        }
        this.displayDropdown = false;
    }

    private gridApi!: GridApi;

    // Method to handle row click event

    currentTabIndex: number = 0; // Default to tab2Content
    onRowDoubleClicked(event: RowDoubleClickedEvent<any>) {
        const rowData = event.data?.id; // Safely access row ID
        if (this.userHasViewAccess && this.jwt !== '') {
            this.disableSubmit = true
            this.status = true
            this.isNotif = false
            this.isEditMode = false;
            if (rowData) {
                this.selectedDriverId = rowData; // Assign to shared property
                this.fetchDriver(rowData);
                this.currentTabIndex = 0; // Switch to Tab 1
                this.toggleFormControls(true);
            } else {
                this.isNotif = true
                this.updateNotification("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
            }
        } else {
            this.isNotif = true
            this.updateNotification("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
        }
    }

    activateTab(tabId: string) {
        // Remove 'active' class from all tabs
        const tabs = document.querySelectorAll('.tab-pane');
        tabs.forEach(tab => tab.classList.remove('show', 'active'));

        // Add 'active' class to the target tab
        const targetTab = document.getElementById(tabId);
        if (targetTab) {
            targetTab.classList.add('show', 'active');
        } else {
            // console.error(`Tab with id "${tabId}" not found.`);
        }
    }
    onGridReady(params: { api: GridApi }) {
        this.gridApi = params.api;
    }

    onCellContextMenu(event: CellContextMenuEvent) {
        if (event && event.event) {
            this.selectedDriver = event.node.data.id;
            this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
            this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
            this.displayDropdown = true;
            event.event.preventDefault();
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        this.displayDropdown = false;
    }
    // END RIGHT CLICK CONTEXT MENU

    // START DROPDOWN OF CRITERIA
    selectFilter(event: Event) {
        const target = event.target as HTMLSelectElement;
        this.selectedFilter = target.value;
    }

    getCriteriaLabel(criteria: FilterCriteria): string {
        switch (criteria.selectedOption) {
            case 'firstName':
                return `First Name: ${criteria.firstNameFrom || ''} - ${criteria.firstNameTo || ''}`;
            case 'lastName':
                return `Last Name: ${criteria.lastNameFrom || ''} - ${criteria.lastNameTo || ''}`;
            case 'middleName':
                return `Middle Name: ${criteria.middleNameFrom || ''} - ${criteria.middleNameTo || ''}`;
            case 'createdDate':
                return `Created Date: ${criteria.startDate ? criteria.startDate.toLocaleDateString() : ''} - ${criteria.endDate ? criteria.endDate.toLocaleDateString() : ''}`;
            case 'truckerName':
                return `Trucker Name: ${criteria.truckerNameFrom || ''} - ${criteria.truckerNameTo || ''}`;
            case 'licenseNo':
                return `License No: ${criteria.licenseNoFrom || ''} - ${criteria.licenseNoTo || ''}`;
            default:
                return '';
        }
    }
    // END DROPDOWN OF CRITERIA

    //START SET FROM OR TO SAME VALUE IF ONE FIELDS IS MISSING
    private setDefaultToValues(criteria: FilterCriteria) {
        if (!criteria.firstNameTo) {
            criteria.firstNameTo = criteria.firstNameFrom;
        } else if (!criteria.firstNameFrom) {
            criteria.firstNameFrom = criteria.firstNameTo;
        }
        if (!criteria.lastNameTo) {
            criteria.lastNameTo = criteria.lastNameFrom;
        } else if (!criteria.lastNameFrom) {
            criteria.lastNameFrom = criteria.lastNameTo;
        }
        if (!criteria.middleNameTo) {
            criteria.middleNameTo = criteria.middleNameFrom;
        } else if (!criteria.middleNameFrom) {
            criteria.middleNameFrom = criteria.middleNameTo;
        }
        if (!criteria.licenseNoTo) {
            criteria.licenseNoTo = criteria.licenseNoFrom;
        } else if (!criteria.licenseNoFrom) {
            criteria.licenseNoFrom = criteria.licenseNoTo;
        }
        if (!criteria.truckerNameTo) {
            criteria.truckerNameTo = criteria.truckerNameFrom;
        } else if (!criteria.truckerNameFrom) {
            criteria.truckerNameFrom = criteria.truckerNameTo;
        }
        if (!criteria.endDate) {
            criteria.endDate = criteria.startDate;
        } else if (!criteria.startDate) {
            criteria.startDate = criteria.endDate;
        }
    }
    //END SET FROM OR TO SAME VALUE IF ONE FIELDS IS MISSING

    //START REMOVE INPUTS WHEN CRITERIA IS REMOVED
    addCriteria() {
        // Check if the search form is null, empty, or invalid
        const criteria: FilterCriteria = this.searchForm.value;

        this.setDefaultToValues(criteria);

        // Convert to number if necessary
        criteria.truckerNameFrom = criteria.truckerNameFrom ? Number(criteria.truckerNameFrom) : null;
        criteria.truckerNameTo = criteria.truckerNameTo ? Number(criteria.truckerNameTo) : null;

        // Check if the criteria already exists
        const existingIndex = this.criteriaList.findIndex(
            (item) => item.selectedOption === criteria.selectedOption
        );

        if (existingIndex !== -1) {
            this.criteriaList[existingIndex] = criteria;
        } else {
            this.criteriaList.push(criteria);
        }
        // Store updated criteria in local storage
        localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));
    }

    // END ADD CRITERIA

    // START SEARCH ALL OF LIST IN CRITERIA FILTER
    onSearch() {
        if (!this.jwt) {
            this.isNotif = true
            this.updateNotification("You don't have access on this module", 'Failed', 'danger');
            return;
        }
        if (this.criteriaList.length === 0) {
            this.filteredRowData = [];
            return;
        }
        this.isLoading = true
        this.driverService.searchDrivers(this.criteriaList).subscribe({
            next: (data) => {
                this.filteredRowData = data;
                if (this.filteredRowData) {
                    this.isLoading = false
                }
            },
            error: (error) => {
                this.isNotif = true
                this.updateNotification("Please contact developers", 'Failed', 'danger');
            }
        });
    }

    // END SEARCH ALL OF LIST IN CRITERIA FILTER

    //START REMOVE INPUTS WHEN CRITERIA IS REMOVED
    resetFormControls(selectedOption: string) {
        switch (selectedOption) {
            case 'firstName':
                this.searchForm.patchValue({
                    firstNameFrom: '',
                    firstNameTo: ''
                });
                break;
            case 'lastName':
                this.searchForm.patchValue({
                    lastNameFrom: '',
                    lastNameTo: ''
                });
                break;
            case 'middleName':
                this.searchForm.patchValue({
                    middleNameFrom: '',
                    middleNameTo: ''
                });
                break;
            case 'truckerName':
                this.searchForm.patchValue({
                    truckerNameFrom: '',
                    truckerNameTo: ''
                });
                break;
            case 'licenseNo':
                this.searchForm.patchValue({
                    licenseNoFrom: '',
                    licenseNoTo: ''
                });
                break;
            case 'createdDate':
                this.searchForm.patchValue({
                    startDate: '',
                    endDate: ''
                });
                break;
            default:
                break;
        }
    }

    removeCriteria(index: number) {
        // Identify the criterion to remove
        const removedCriterion = this.criteriaList[index];

        // Remove the criterion from the list
        this.criteriaList.splice(index, 1);

        // Reset the form controls based on the removed criterion
        this.resetFormControls(removedCriterion.selectedOption);
        localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));

        // Perform search with updated criteria list
        // this.onSearch();
    }
    //END REMOVE INPUTS WHEN CRITERIA IS REMOVED
    @HostListener('window:beforeunload', ['$event'])
    clearLocalStorage(event: Event): void {
        localStorage.clear();  // Clears all localStorage data
    }


    //START MODAL FOR COMPANY NAME
    openModal() {
        this.truckerService.getAllTruckers().subscribe({
            next: (data) => {
                this.truckerData = data
            },
            error: () => {
                this.isNotif = true
                this.updateNotification("Please contact developers", 'Failed', 'danger');
            }
        })
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = new bootstrap.Modal(modalElement);
        modalInstance.show();
    }

    closeModal() {
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    }

    rowClicked(event: any): void {
        this.clickedRowData = event.data;
        this.driverForm.get('truckerId')?.setValue(event.data.id);
        this.driverForm.get('truckerName')?.setValue(event.data.name);
        this.closeModal();
    }
    //END MODAL FOR COMPANY NAME
    onBlackListTypeSelectItem(item: any): void {
        this.selectedBlacklistType = item.description;
        this.driverForm.patchValue({ driverBlacklistTypeId: item.id });
    }
  

    // START EMPLOYEE STATUS DROPDOWN
    getEmploymentStatusLabel(): string {
        const statusValue = this.driverForm?.get('status')?.value;
        const selectedOption = this.employmentStatusOptions?.find(option => option.value === statusValue);
        return selectedOption ? selectedOption.label : 'Select';
    }

    selectEmploymentStatus(value: any): void {
        this.driverForm.get('status')?.setValue(value);
    }
    // END EMPLOYEE STATUS DROPDOWN

    //START CONTEXT FOR FORM
    onInformationRightClick(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition.x = `${event.clientX}px`;
        this.contextMenuPosition.y = `${event.clientY}px`;
        this.displayDropdown = true;
    }

    onCancel() {
        // this.router.navigate(['/driver/list']);
    }

    onFindDriver() {
        this.currentTabIndex = 1
        this.displayDropdown = false;
    }

    onRightCreateDriver() {
        this.toggleFormControls(false);
        this.selectedDriverId = null
        this.driverForm.reset({
            firstName: '',
            lastName: '',
            middleName: '',
            nickName: '',
            contactNumber: '',
            yearsOfService: '',
            truckerId: '',
            licenseNo: '',
            licenseExpiry: '',
            licenseType: '',
            fileNumber: '',
            filePath: '',
            licenseNoFilePath: '',
            createdDate: '',
            lastUpdatedDate: '',
            status: true,
            driverBlacklistTypeId: '',
            hasBiodata: false,
            hasNbiclearance: false,
            hasBarangayClearance: false,
            hasPoliceClearance: false,
            hasPreDefensiveDriveCourse: false,
            hasDefensiveDriveCourse: false,
            hasShe: false,
            hasGoodWarehousePractice: false,
            apedate: '',
            drugTestDate: '',
        })
        this.status = false
        this.selectedBlacklistType = 'Select'
        this.displayDropdown = false;
        this.disableSubmit = false
    }


    toggleFormControls(disable: boolean): void {
        Object.keys(this.driverForm.controls).forEach(controlName => {
            const control = this.driverForm.get(controlName);
            if (control) {
                if (disable) {
                    control.disable({ onlySelf: true });
                } else {
                    control.enable({ onlySelf: true });
                }
            }
        });
    }

    onRightEditDriver() {
        this.displayDropdown = false;
        this.toggleFormControls(false);
        this.disableSubmit = false
        this.isEditMode = true;
        this.status = true
    }
    //END CONTEXT FOR FORM
    onTabChange(index: number) {
        if (index === 0) {
            this.isNotif = false
            this.onRightCreateDriver();
        }
    }
}
