import { Component, OnInit, HostListener } from '@angular/core';
import { Driver } from '../../model/driver.model';
import { DriverService } from '../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DriverBlacklistType } from '../../model/driverBlacklist.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddDriverBlacklistComponent } from '../add-driver-blacklist/add-driver-blacklist.component';
import { TokenService } from '../../service/token.service';
import { userAccess } from '../../model/userAccess.model';
@Component({
  selector: 'app-driver-blacklist',
  templateUrl: './driver-blacklist.component.html',
  styleUrls: ['./driver-blacklist.component.css'],
})

export class DriverBlacklistComponent implements OnInit {
  searchForm: FormGroup;
  Token: string = '';
  userHasEditAccess: boolean = false;
  userHasCreateAccess: boolean = false;
  userHasViewAccess: boolean = false;
  userHasDeleteAccess: boolean = false;
  access: any[] = [];
  menuId: number = 3

  constructor(private driverService: DriverService, private router: Router, private route: ActivatedRoute,
    private fb: FormBuilder, private dialogRef: MatDialog, private tokenService: TokenService) {
    this.searchForm = this.fb.group({
      selectedOption: ['description'],
      description: [''],
      status: ['Active'],
      createStartDate: [''],
      createEndDate: [''],
      updateStartDate: [''],
      updateEndDate: ['']
    });
  }

  openAddBlacklist() {
    this.dialogRef.open(AddDriverBlacklistComponent, {
      data: {
        blacklistType: null
      }
    });
  }

  onEditBlacklist() {
    this.dialogRef.open(AddDriverBlacklistComponent, {
      data: {
        blacklistType: this.selectedBlacklistType
      }
    });
  }


  columnDefs: ColDef[] = [
    { field: 'description', headerName: 'Description' },
    { field: 'status', headerName: 'Status' },
    { field: 'createdByUserId', headerName: 'Created By' },
    { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter, floatingFilter: false, filter: false },
    { field: 'lastUpdatedByUserId', headerName: 'Updated By' },
    { field: 'lastUpdatedDate', headerName: 'Updated Date', valueFormatter: this.dateFormatter, floatingFilter: false, filter: false },
  ];
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  };
  rowData: any[] = [];
  filteredRowData: any[] = [];
  blacklist: DriverBlacklistType[] = [];
  selectedBlacklistType: DriverBlacklistType | null = null;
  selectedFilter: string | null = null;
  searchBlacklistType: string = '';
  displayDropdown = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  isSearchNotNull: boolean = true;
  isClear: boolean = false;
  loading: boolean = true;
  criteriaList: any[] = [];
  dateError: boolean = false

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['config']) {
        try {
          // Decode and parse the 'config' from query params
          const config = JSON.parse(decodeURIComponent(params['config']));
          console.log('Token stored:', config.Id);
          console.log('Menuid stored:', 'menuId:', params['menuId']);
          // Set the token using the token service
          this.tokenService.setToken(config.Id);
          const menuId = params['menuId']
          // Now that the token is set, proceed to call getAccess with the token
          this.tokenService.getAccess(config.Id, menuId).subscribe({
            next: (data: userAccess[]) => {
              console.log('Access data received:', data);
              this.access = data;
              this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
              this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
              this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
              this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
            },
            error: (err) => console.error('Error loading access data:', err),
          });
        } catch (error) {
          console.error('Error parsing config JSON:', error);
        }
      } else {
        console.warn("Config parameter missing from URL query params.");
      }
    });
    this.driverService.getAllBlacklist().subscribe({
      next: (data) => {
        this.rowData = data;
        this.filteredRowData = this.rowData;
      },
      error: (response) => {
      },
    })
  }

  dateFormatter(params: any): string {
    const date = new Date(params.value);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString();
    }
    return params.value;
  }

  selectFilter(event: Event) {

  }

  addCriteria() {
      const criteria = this.searchForm.value;
      const existingIndex = this.criteriaList.findIndex(
        (item) => item.selectedOption === criteria.selectedOption
      );
      if (criteria.selectedOption === 'description') {
        if (criteria.description === '')
          return
      }
      if (criteria.selectedOption === 'createdDate') {
        if (criteria.createEndDate < criteria.createStartDate || criteria.createStartDate == '' || criteria.createEndDate == '') {
          this.dateError = true
          return
        }
      }
      if (criteria.selectedOption === 'lastUpdatedDate') {
        if (criteria.updateEndDate < criteria.updateStartDate || criteria.updateStartDate == '' || criteria.updateEndDate == '') {
          this.dateError = true
          return
        }
      }
      if (existingIndex !== -1) {
        this.criteriaList[existingIndex] = criteria;
      } else {
        this.criteriaList.push(criteria);
        this.dateError = false
      }
    }

  removeCriteria(index: number) {
    this.criteriaList.splice(index, 1);
    this.onSearch();
  }

  onSearch() {
    if (this.criteriaList.length === 0) {
      this.filteredRowData = this.rowData;
    } else {
      this.filteredRowData = this.rowData.filter((driverBlacklist) => {
        return this.criteriaList.every((criteria) => {
          if (criteria.selectedOption === 'description' && criteria.description) {
            return driverBlacklist.description.toLowerCase().includes(criteria.description.toLowerCase());
          }
          if (criteria.selectedOption === 'isActive') {
            if (criteria.status === 'Active' && driverBlacklist.status ||
              criteria.status === 'Inactive' && !driverBlacklist.status)
              return true;
            else return false;
          }
          if (criteria.selectedOption === 'createdDate' && criteria.createStartDate && criteria.createEndDate) {
            const createdDate = new Date(driverBlacklist.createdDate);
            const start = new Date(criteria.createStartDate);
            start.setHours(0, 0, 0, 0);
            const end = new Date(criteria.createEndDate);
            end.setHours(23, 59, 59, 99);
            return createdDate >= start && createdDate <= end;
          }
          if (criteria.selectedOption === 'lastUpdatedDate' && criteria.updateStartDate && criteria.updateEndDate) {
            const createdDate = new Date(driverBlacklist.lastUpdatedDate);
            const start = new Date(criteria.updateStartDate);
            start.setHours(0, 0, 0, 0);
            const end = new Date(criteria.updateEndDate);
            end.setHours(23, 59, 59, 99);
            return createdDate >= start && createdDate <= end;
          }
          return true;
        });
      });
    }
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = (event as MouseEvent).clientX + 'px';
    this.contextMenuPosition.y = (event as MouseEvent).clientY + 'px';
    this.displayDropdown = true;
  }

  onCellContextMenu(event: CellContextMenuEvent) {
    if (event && event.event) {
      this.selectedBlacklistType = event.node.data;
      this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
      this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
      this.displayDropdown = true;
      event.event.preventDefault();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    this.displayDropdown = false;
  }
}