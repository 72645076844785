import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { DriverService } from '../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastComponent } from '../toast/toast.component';
import { ColDef, GridApi, CellContextMenuEvent } from 'ag-grid-community';
import { DriverBlacklistType } from '../../model/driverBlacklist.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var bootstrap: any;

@Component({
  selector: 'app-add-driver-blacklist',
  templateUrl: './add-driver-blacklist.component.html',
  styleUrl: './add-driver-blacklist.component.css'
})

export class AddDriverBlacklistComponent implements OnInit{
    @ViewChild(ToastComponent) toastComponent!: ToastComponent;
    inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3'

    columnDefs: ColDef[] = [
        { field: 'description', headerName: 'Description'},
        { field: 'status', headerName: 'Status' }
    ];

    blacklistId: string | null = null;

    constructor(
      private driverService: DriverService,
      private route: ActivatedRoute,
      private router: Router,
      private ref: MatDialogRef<AddDriverBlacklistComponent>,
      @Inject(MAT_DIALOG_DATA) private data: any
    ) {
      if (data.blacklistType != null) {
        this.driverBlacklistForm.patchValue({
          description: data.blacklistType.description,
          status: data.blacklistType.status,
          createdByUserId: data.blacklistType.createdByUserId,
          createdDate: data.blacklistType.createdDate
        })
        this.blacklistId = data.blacklistType.id
      }
    }
    driverBlacklistForm: FormGroup = new FormGroup({
      description: new FormControl(''),
      status: new FormControl(true),
      createdByUserId: new FormControl(''),
      createdDate: new FormControl(''),
      lastUpdatedByUserId: new FormControl(''),
      lastUpdatedDate: new FormControl(''),
    })

    saveBlacklist() {
      if (this.driverBlacklistForm.get('description')?.value === '') {
        this.showToast("Please enter a description.",'Failed','danger');
        return;
      } else {
        const currentDate = new Date()
        const userID = null // default value

        if (this.blacklistId == null) {
          this.driverBlacklistForm.patchValue({
            createdDate: currentDate,
            createdByUserId: userID
          })
          this.driverBlacklistForm.patchValue({
            lastUpdatedDate: currentDate,
            lastUpdatedByUserId: userID
          })
          this.driverService.addBlacklist(this.driverBlacklistForm.value).subscribe({
            next: (response) => {
              this.ref.close();
            },
            error: (error) => {
              console.log(error);
            },
            complete: () => {
            }
          });
        }
        else {
          this.driverBlacklistForm.patchValue({
            lastUpdatedDate: currentDate,
            lastUpdatedByUserId: userID
          })
          this.driverService.updateBlacklist(this.blacklistId,this.driverBlacklistForm.value).subscribe({
            next: (response) => {
              this.ref.close();
            },
            error: (error) => {
              console.log(error);
            },
            complete: () => {

            }
          })
        }
      }
    }
  
    ngOnInit(): void {
    }
    
    showToast(message: string, header: string, status: string) {
      this.toastComponent.message = message;
      this.toastComponent.header = header;
      this.toastComponent.status = status;
      this.toastComponent.showToast();
    }
}