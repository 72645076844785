import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DriverService } from '../../../service/driver.service';
import { Driver } from '../../../model/driver.model';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrl: './driver.component.css'
})

export class DriverComponent {
  inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3'
  docInputStyle = 'row align-items-center d-flex  flex-md-row mb-3'

  list: boolean = false
  info: boolean = true
  constructor(private router: Router) {

  }
  listOnClick() {
    this.router.navigate(['driver/driverlist']);
  }
  infoOnClick() {
    this.router.navigate(['driver/add-driver']);
  }

}

