import { Component, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TruckService } from '../../../service/truck.service';
import { ToastComponent } from '../../toast/toast.component';
import { _createIconNoSpan, ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { TruckerService } from '../../../service/trucker.service';
import { TrucktypeService } from '../../../service/trucktype.service';
import { BusinessunitService } from '../../../service/businessunit.service';
import { TruckOperationSiteTaggingService } from '../../../service/truck-operation-site-tagging.service';
import { TruckmovementhistoryService } from '../../../service/truckmovementhistory.service';
import { TokenService } from '../../../service/token.service';
import { userAccess } from '../../../model/userAccess.model';
declare var bootstrap: any;

@Component({
    selector: 'app-add-edit-truck',
    templateUrl: './add-edit-truck.component.html',
    styleUrl: './add-edit-truck.component.css'
})
export class AddEditTruckComponent {
    inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3';
    @ViewChild(ToastComponent) toastComponent!: ToastComponent;


    truckStatusOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];
    ownerShipOptions = [
        { id: 1, label: 'COMPANY' },
        { id: 2, label: 'LEASED' },
        { id: 3, label: 'MORTGAGE' },
        { id: 4, label: 'OUTSOURCED' },
        { id: 5, label: 'OWNED' },
        { id: 6, label: 'PRIVATE' },
    ];
    showTaggingTable: boolean = true;
    showMovementHistoryTable: boolean = false;
    contextMenuPosition = { x: '0px', y: '0px' };
    Token: string = '';
    UserName: string = '';
    isEdit: boolean = false;
    clickedRowData: any = null;
    isEditMode: boolean = false;
    truckId: string | null = null;
    truckTypeId: string | null = null;
    fieldDisabled: boolean = false;
    displayDropdown = false;
    truckTaggingEdit: boolean = false;
    access: any[] = [];
    businessUnitData: any[] = [];
    truckList: any[] = [];
    truckerData: any[] = [];
    truckTypeData: any[] = [];
    truckOpsData: any[] = [];
    rowData: any[] = [];
    rowTruckData: any[] = [];
    updateTruckTagging: any[] = [];
    idFromTruckList: string | null = "";
    dataFromtruckTagging: any[] = [];
    dataFromTrucktaggingAndTruck: any[] = []
    dataFromBusinessUnit: any[] = [];
    dataFromTruckMovementHistory: any[] = [];
    userHasEditAccess: boolean = false;
    userHasCreateAccess: boolean = false;
    userHasViewAccess: boolean = false;
    userHasDeleteAccess: boolean = false;
    menuId: number = 2
    private gridApi: GridApi | undefined;

    // CobrowDatacolumnDefs: ColDef[] = [
    //     { field: 'businessUnitId', headerName: 'BusinessUnit ID' },
    //     { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
    //     { field: 'isDedicated', headerName: 'isDedicated', editable: (params) => !this.fieldDisabled, cellRenderer: 'agCheckboxCellRenderer' },
    //     { field: 'status', headerName: 'Status', editable: (params) => !this.isEdit, cellRenderer: 'agCheckboxCellRenderer' },
    //     { field: 'remarks', headerName: 'Remarks', editable: (params) => !this.isEdit },
    //     { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
    //     { field: 'lastUpdatedDate', headerName: 'LastUpdate Date', valueFormatter: this.dateFormatter },
    // ]
    CobrowDatacolumnDefs: ColDef[] = [
        { field: 'businessUnitId', headerName: 'BusinessUnit ID' },
        { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
        { field: 'isDedicated', headerName: 'isDedicated', editable: (params) => !this.fieldDisabled, cellRenderer: 'agCheckboxCellRenderer' },
        { field: 'status', headerName: 'Status', editable: (params) => !this.fieldDisabled, cellRenderer: 'agCheckboxCellRenderer' },
        { field: 'remarks', headerName: 'Remarks', editable: (params) => !this.fieldDisabled },
        { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
        { field: 'lastUpdatedDate', headerName: 'LastUpdate Date', valueFormatter: this.dateFormatter },
    ]

    BusinessUnitcolumnDefs: ColDef[] = [
        { field: 'businessUnitId', headerName: 'Business' },
        { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
        { field: 'isDedicated', headerName: 'isDedicated', editable: true, cellRenderer: 'agCheckboxCellRenderer' },
        { field: 'status', headerName: 'Status', editable: true, cellRenderer: 'agCheckboxCellRenderer' },
        { field: 'remarks', headerName: 'Remarks', editable: true },
    ]
    truckMovementHistorycolumnDefs: ColDef[] = [
        { field: 'businessUnitName', headerName: 'BusinessUnit Name' },
        { field: 'description', headerName: 'Description' },
        { field: 'createdByUserId', headerName: 'CreatedByUserId' },
        { field: 'createdDate', headerName: 'CreatedDate', valueFormatter: this.dateFormatter },
    ]

    getColumnDef(): ColDef[] {
        return this.idFromTruckList === undefined || null ? this.BusinessUnitcolumnDefs : this.CobrowDatacolumnDefs;
    }
    getData() {
        return this.idFromTruckList === undefined || null ? this.dataFromBusinessUnit : this.dataFromTrucktaggingAndTruck
    }

    columnBusinessUnitDefs: ColDef[] = [
        { field: 'id', headerName: 'Location' },
        { field: 'name', headerName: 'Description' },
        { field: 'status', headerName: 'Status' },
    ];

    columnTruckDefs: ColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'type', headerName: 'Truck Type' },
    ];

    columnDefs: ColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Company Name' },
    ];

    public defaultTruckColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
    };
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
    };


    truckForm: FormGroup = new FormGroup({
        plateNo: new FormControl({ value: '', disabled: false }),
        truckTypeId: new FormControl({ value: '', disabled: false }),
        truckTypeName: new FormControl({ value: '', disabled: false }),
        truckerId: new FormControl({ value: '', disabled: false }),
        truckerName: new FormControl({ value: '', disabled: false }),
        vanType: new FormControl({ value: '', disabled: false }),
        plateType: new FormControl({ value: 0, disabled: false }),
        year: new FormControl({ value: null, disabled: false }),
        make: new FormControl({ value: '', disabled: false }),
        body: new FormControl({ value: '', disabled: false }),
        weightCapacity: new FormControl({ value: null, disabled: false }),
        chassisNumber: new FormControl({ value: '', disabled: false }),
        engineNumber: new FormControl({ value: '', disabled: false }),
        companyName: new FormControl({ value: '', disabled: false }),
        ownershipTypeId: new FormControl({ value: '', disabled: false }),
        owner: new FormControl({ value: '', disabled: false }),
        crNumber: new FormControl({ value: '', disabled: false }),
        crDate: new FormControl({ value: '', disabled: false }),
        orNumber: new FormControl({ value: '', disabled: false }),
        orDate: new FormControl({ value: '', disabled: false }),
        acquisitionDate: new FormControl({ value: '', disabled: false }),
        acquisitionCost: new FormControl({ value: 0, disabled: false }),
        emissionResult: new FormControl({ value: false, disabled: false }),
        emissionDate: new FormControl({ value: '', disabled: false }),
        hasGPS: new FormControl({ value: false, disabled: false }),
        gpsProvider: new FormControl({ value: '', disabled: false }),
        odoMeter: new FormControl({ value: null, disabled: false }),
        fuelType: new FormControl({ value: '', disabled: false }),
        aveKMPerLiter: new FormControl({ value: null, disabled: false }),
        insurer: new FormControl({ value: '', disabled: false }),
        coverage: new FormControl({ value: null, disabled: false }),
        insuranceExpiryDate: new FormControl({ value: '', disabled: false }),
        policyNumber: new FormControl({ value: '', disabled: false }),
        deployment: new FormControl({ value: '', disabled: false }),
        garage: new FormControl({ value: '', disabled: false }),
        isForBilling: new FormControl({ value: false, disabled: false }),
        ltfrbExpirationDate: new FormControl({ value: '', disabled: false }),
        remarks: new FormControl({ value: '', disabled: false }),
        createdDate: new FormControl({ value: '', disabled: false }),
        lastUpdatedDate: new FormControl({ value: '', disabled: false }),
        status: new FormControl({ value: true, disabled: false }),
        createdByUserId: new FormControl({ value: '', disabled: false }),
        lastUpdatedByUserId: new FormControl({ value: '', disabled: false }),

    });
    constructor(private route: ActivatedRoute, private truckService: TruckService, private truckerService: TruckerService, private truckTypeService: TrucktypeService, private router: Router, private businessUnitService: BusinessunitService, private fb: FormBuilder, private truckOperationSiteTagging: TruckOperationSiteTaggingService, private truckMovementHistory: TruckmovementhistoryService, private tokenService: TokenService) {
    }
    showToast(message: string, header: string, status: string) {
        this.toastComponent.message = message;
        this.toastComponent.header = header;
        this.toastComponent.status = status;
        this.toastComponent.showToast();
    }
    // ngOnInit(): void {

    //     const state = history.state as { fieldDisabled?: boolean, truckId: string };
    //     this.fieldDisabled = state?.fieldDisabled ?? false;
    //     this.idFromTruckList = state?.truckId

    //     this.route.paramMap.subscribe(params => {
    //         // Iterate over each control in the FormGroup
    //         Object.keys(this.truckForm.controls).forEach(controlName => {
    //             const control = this.truckForm.get(controlName);
    //             if (control) {
    //                 if (this.fieldDisabled) {
    //                     control.disable({ onlySelf: true });
    //                 } else {
    //                     control.enable({ onlySelf: true });
    //                 }
    //             }
    //         });
    //     });
    //     this.truckTypeId = this.route.snapshot.paramMap.get('id');
    //     this.truckService.getAllTruck().subscribe({
    //         next: (data) => {
    //             this.truckList = data;
    //         }
    //     });

    //     this.truckTypeService.getAllTruckType().subscribe({
    //         next: (data) => {
    //             this.rowTruckData = data;
    //             this.truckTypeData = data
    //         }
    //     })

    //     this.isEdit = this.fieldDisabled
    //     this.truckerService.getAllTruckers().subscribe({
    //         next: (data) => {
    //             this.truckerData = data;
    //             this.rowData = data;
    //             // Load driver data after trucker data has been loaded

    //         }
    //     });

    //     if (this.idFromTruckList) {
    //         this.isEditMode = true;
    //         this.truckService.getTruckById(this.idFromTruckList).subscribe(truck => {
    //             // Find the trucker by ID
    //             const trucker = this.truckerData.find(t => t.id === truck.truckerId);
    //             const truckType = this.truckTypeData.find(t => t.id === truck.truckTypeId);
    //             // Update the form values
    //             this.truckForm.patchValue({
    //                 truckerName: trucker ? trucker.name : null, // Use the trucker's name if found
    //                 truckTypeName: truckType ? truckType.type : null, // Use the trucker's name if found
    //                 ...truck,
    //                 crDate: truck.crDate ? new Date(truck.crDate).toISOString().split('T')[0] : null,
    //                 orDate: truck.orDate ? new Date(truck.orDate).toISOString().split('T')[0] : null,
    //                 acquisitionDate: truck.acquisitionDate ? new Date(truck.acquisitionDate).toISOString().split('T')[0] : null,
    //                 emissionDate: truck.emissionDate ? new Date(truck.emissionDate).toISOString().split('T')[0] : null,
    //                 insuranceExpiryDate: truck.insuranceExpiryDate ? new Date(truck.insuranceExpiryDate).toISOString().split('T')[0] : null,
    //                 ltfrbExpirationDate: truck.ltfrbExpirationDate ? new Date(truck.ltfrbExpirationDate).toISOString().split('T')[0] : null,
    //                 // Convert to YYYY-MM-DD format to display in the edit
    //             });
    //         });
    //     }
    //     if (this.idFromTruckList) {
    //         this.truckOperationSiteTagging.getBusinessUnitAndTruckOpsLinq(this.idFromTruckList).subscribe({
    //             next: (data) => {
    //                 this.dataFromTrucktaggingAndTruck = data
    //                 console.log(data)
    //             }

    //         })
    //     } else {
    //         this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
    //             next: (data) => {
    //                 this.dataFromBusinessUnit = data
    //                 console.log("get all ", data)
    //             }
    //         })
    //     }
    // }


    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['config']) {
                try {
                    // Decode and parse the 'config' from query params
                    const config = JSON.parse(decodeURIComponent(params['config']));
                    console.log('Token stored:', config.Id);
                    console.log('Menuid stored:', 'menuId:', params['menuId']);
                    // Set the token using the token service
                    this.tokenService.setToken(config.Id);
                    const menuId = params['menuId']
                    // Now that the token is set, proceed to call getAccess with the token
                    this.tokenService.getAccess(config.Id, menuId).subscribe({
                        next: (data: userAccess[]) => {
                            console.log('Access data received:', data);
                            this.access = data;
                            this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                            this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                            this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                            this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
                        },
                        error: (err) => console.error('Error loading access data:', err),
                    });
                } catch (error) {
                    console.error('Error parsing config JSON:', error);
                }
            } else {
                console.warn("Config parameter missing from URL query params.");
            }
        });

        const isPageRefreshed = sessionStorage.getItem('pageRefreshed') === 'true';
        this.truckerService.getAllTruckers().subscribe({
            next: (data) => {
                this.truckerData = data;
                this.rowData = data;
            }
        });
        if (isPageRefreshed) {
            this.resetForm();
            sessionStorage.removeItem('pageRefreshed');
        } else {
            const state = history.state as { fieldDisabled?: boolean, truckId?: string };
            this.fieldDisabled = state?.fieldDisabled ?? false;
            this.idFromTruckList = state?.truckId || null;
            this.route.paramMap.subscribe(params => {
                Object.keys(this.truckForm.controls).forEach(controlName => {
                    const control = this.truckForm.get(controlName);
                    if (control) {
                        if (this.fieldDisabled) {
                            control.disable({ onlySelf: true });
                        } else {
                            control.enable({ onlySelf: true });
                        }
                    }
                });
            });

            // Fetch truckers and truck types first
            this.fetchTruckersAndTypes().then(() => {
                if (this.idFromTruckList) {
                    this.isEditMode = true;
                    this.loadTruckData(this.idFromTruckList);  // Load truck data after truckers and truck types are loaded
                    this.loadTruckOperationSiteTagging(this.idFromTruckList);  // Load tagging
                } else {
                    this.loadAllBusinessUnitAndTruckOps();
                }
            });
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload(event: Event): void {
        sessionStorage.setItem('pageRefreshed', 'true');
    }

    // Method to fetch truckers and truck types
    fetchTruckersAndTypes(): Promise<void> {
        return new Promise((resolve, reject) => {
            // Fetch truckers
            this.truckerService.getAllTruckers().subscribe({
                next: (data) => {
                    this.truckerData = data;
                    this.rowData = data;
                    // Fetch truck types
                    this.truckTypeService.getAllTruckType().subscribe({
                        next: (data) => {
                            this.rowTruckData = data;
                            this.truckTypeData = data;
                            resolve();  // Resolve the promise once both are loaded
                        },
                        error: () => reject()
                    });
                },
                error: () => reject()
            });
        });
    }

    resetForm(): void {
        this.truckForm.reset();
        this.isEditMode = false;
        this.fetchTruckersAndTypes()
        this.loadAllBusinessUnitAndTruckOps()
    }

    loadTruckData(truckId: string): void {
        this.truckService.getTruckById(truckId).subscribe(truck => {
            console.log('Truck Data:', truck);
            const trucker = this.truckerData.find(t => t.id === truck.truckerId);
            const truckType = this.truckTypeData.find(t => t.id === truck.truckTypeId);

            this.truckForm.patchValue({
                truckerName: trucker ? trucker.name : null,
                truckTypeName: truckType ? truckType.type : null,
                ...truck,
                crDate: truck.crDate ? new Date(truck.crDate).toISOString().split('T')[0] : null,
                orDate: truck.orDate ? new Date(truck.orDate).toISOString().split('T')[0] : null,
                acquisitionDate: truck.acquisitionDate ? new Date(truck.acquisitionDate).toISOString().split('T')[0] : null,
                emissionDate: truck.emissionDate ? new Date(truck.emissionDate).toISOString().split('T')[0] : null,
                insuranceExpiryDate: truck.insuranceExpiryDate ? new Date(truck.insuranceExpiryDate).toISOString().split('T')[0] : null,
                ltfrbExpirationDate: truck.ltfrbExpirationDate ? new Date(truck.ltfrbExpirationDate).toISOString().split('T')[0] : null,
            });
        });
    }

    loadTruckOperationSiteTagging(truckId: string): void {
        this.truckOperationSiteTagging.getBusinessUnitAndTruckOpsLinq(truckId).subscribe({
            next: (data) => {
                this.dataFromTrucktaggingAndTruck = data;
                console.log('Truck tagging data:', data);
            }
        });
    }

    loadAllBusinessUnitAndTruckOps(): void {
        this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
            next: (data) => {
                this.dataFromBusinessUnit = data;
                console.log('All business units and truck ops:', data);
            }
        });
    }



    onCellValueChanged(event: any): void {
        // console.log('Cell Value Changed Event:', event.data);

        // Get the updated row data
        const updatedData = event.data;
        // Find the index of the row in dataFromtruckTagging based on the unique identifier (id)
        const index = this.dataFromtruckTagging.findIndex(row => row.businessUnitId === updatedData.businessUnitId);

        if (index !== -1) {
            // If found, update the existing data
            this.dataFromtruckTagging[index] = {
                ...this.dataFromtruckTagging[index],
                ...updatedData
            };

        } else {
            this.dataFromtruckTagging.push(updatedData);
        }

    }



    // saveTruck() {
    //     const truckerId = this.truckForm.get('truckerId')?.value;
    //     const truckTypeId = this.truckForm.get('truckTypeId')?.value;

    //     if (!truckerId || !truckTypeId) {
    //         this.showToast('Please Fill-out all empty fields, Thank you', 'Failed', 'danger');
    //         window.scroll(0, 0);
    //         return;
    //     }
    //     const createdUserById = this.access && this.access.length > 0 ? this.access[0].id : null;
    //     const lastUpdatedByUserId = this.access && this.access.length > 0 ? this.access[0].id : null;
    //     const currentDate = new Date();
    //     if (this.isEditMode && this.idFromTruckList) {
    //         this.truckForm.patchValue({
    //             lastUpdatedDate: currentDate,
    //             createdUserById: createdUserById
    //         });
    //         const truckData = this.truckForm.value;
    //         const operationSiteTaggings = this.dataFromtruckTagging;

    //         const combinedTruckData = {
    //             ...truckData,
    //             truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
    //                 businessUnitId: tag.businessUnitId,
    //                 createdDate: new Date().toISOString(),
    //                 lastUpdatedDate: new Date().toISOString(),
    //                 remarks: tag.remarks,
    //                 isDedicated: tag.isDedicated,
    //                 status: tag.status,
    //                 id: tag.id
    //             }))
    //         };

    //         this.truckService.updateTruck(this.idFromTruckList, combinedTruckData).subscribe({
    //             next: () => {
    //                 this.showToast('Updated Truck, Thank you', 'Successfully', 'success');
    //                 window.scroll(0, 0);
    //             },
    //             error: (error) => {
    //                 this.showToast('Failed to update the truck', 'Error', 'danger');
    //             },
    //         });

    //     } else {
    //         this.truckForm.patchValue({
    //             createdDate: currentDate,
    //             lastUpdatedDate: currentDate,
    //             createdUserById: createdUserById,
    //             lastUpdatedByUserId: lastUpdatedByUserId
    //         });
    //         const truckData = this.truckForm.value;
    //         const operationSiteTaggings = this.dataFromtruckTagging;

    //         const combinedTruckData = {
    //             ...truckData,
    //             truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
    //                 businessUnitId: tag.businessUnitId,
    //                 createdDate: new Date().toISOString(),
    //                 lastUpdatedDate: new Date().toISOString(),
    //                 remarks: tag.remarks,
    //                 isDedicated: tag.isDedicated,
    //                 status: tag.status
    //             }))
    //         };


    //         this.truckService.addTruck(combinedTruckData).subscribe({
    //             next: (data) => {
    //                 this.showToast('Added New Truck, Thank you', 'Successfully', 'success');
    //                 window.scroll(0, 0);
    //                 console.log(this.idFromTruckList)
    //             },
    //             error: (error) => {
    //                 this.showToast('Failed to add the truck', 'Error', 'danger');
    //             },
    //         });
    //     }
    // }

    saveTruck() {
        const truckerId = this.truckForm.get('truckerId')?.value;
        const truckTypeId = this.truckForm.get('truckTypeId')?.value;

        if (!truckerId || !truckTypeId) {
            this.showToast('Please Fill-out all empty fields, Thank you', 'Failed', 'danger');
            window.scroll(0, 0);
            return;
        }

        const createdUserById = this.access?.[0]?.user || null;
        const currentDate = new Date();
        if (this.isEditMode && this.idFromTruckList) {
            // Update mode
            this.truckForm.patchValue({
                lastUpdatedDate: currentDate,
                lastUpdatedByUserId: createdUserById
            });
            const truckData = this.truckForm.value;
            const operationSiteTaggings = this.dataFromtruckTagging;

            const combinedTruckData = {
                ...truckData,
                truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
                    businessUnitId: tag.businessUnitId,
                    createdDate: new Date().toISOString(),
                    lastUpdatedDate: new Date().toISOString(),
                    remarks: tag.remarks,
                    isDedicated: tag.isDedicated,
                    status: tag.status,
                    id: tag.id
                }))
            };

            this.truckService.updateTruck(this.idFromTruckList, combinedTruckData).subscribe({
                next: () => {
                    this.showToast('Updated Truck, Thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                    console.log(createdUserById)
                    console.log(this.access)
                },
                error: (error) => {
                    this.showToast('Failed to update the truck', 'Error', 'danger');
                },
            });

        } else {
            // Add new truck mode
            this.truckForm.patchValue({
                createdDate: currentDate,
                lastUpdatedDate: currentDate,
                createdByUserId: createdUserById,
            });
            const truckData = this.truckForm.value;
            const operationSiteTaggings = this.dataFromtruckTagging;

            const combinedTruckData = {
                ...truckData,
                truckOperationSiteTaggings: operationSiteTaggings.map(tag => ({
                    businessUnitId: tag.businessUnitId,
                    createdDate: new Date().toISOString(),
                    lastUpdatedDate: new Date().toISOString(),
                    remarks: tag.remarks,
                    isDedicated: tag.isDedicated,
                    status: tag.status
                }))
            };

            this.truckService.addTruck(combinedTruckData).subscribe({
                next: (data) => {
                    this.showToast('Added New Truck, Thank you', 'Successfully', 'success');
                    window.scroll(0, 0);
                    console.log(this.idFromTruckList);
                    console.log(createdUserById)
                },
                error: (error) => {
                    this.showToast('Failed to add the truck', 'Error', 'danger');
                },
            });
        }
    }

    //START MODAL FOR TruckType
    openTruckModal() {
        const modalElement = document.getElementById('truckTypeModal');
        const modalInstance = new bootstrap.Modal(modalElement);
        modalInstance.show();
    }

    closeTruckModal() {
        const modalElement = document.getElementById('truckTypeModal');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    }

    rowTruckClicked(event: any): void {
        this.clickedRowData = event.data;
        this.truckForm.get('truckTypeId')?.setValue(event.data.id);
        this.truckForm.get('truckTypeName')?.setValue(event.data.type);
        this.closeTruckModal();
    }
    //END MODAL FOR TruckType

    //START MODAL FOR Trucker Name
    openModal() {
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = new bootstrap.Modal(modalElement);
        modalInstance.show();

    }

    closeModal() {
        const modalElement = document.getElementById('truckerModal');
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    }

    rowClicked(event: any): void {
        this.clickedRowData = event.data;
        this.truckForm.get('truckerId')?.setValue(event.data.id);
        this.truckForm.get('truckerName')?.setValue(event.data.name);
        this.closeModal();
    }
    //START MODAL FOR Trucker Name

    onRightClick(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition = {
            x: `${event.pageX}px`,
            y: `${event.pageY}px`
        };
        this.displayDropdown = true;
    }

    onFindDriver() {
        this.router.navigate(['/truck/list']);
        this.displayDropdown = false;
    }

    onCreateTruck() {
        this.router.navigate(['/truck/form'])
        this.toggleFormControls(false);
        this.idFromTruckList = null
        this.truckOperationSiteTagging.getBusinessUnitAndTruckOps().subscribe({
            next: (data) => {
                this.dataFromBusinessUnit = data
                console.log("data ", this.dataFromBusinessUnit)
            }
        })
        console.log(this.idFromTruckList)
        this.truckForm.reset({
            plateNo: null,
            truckTypeId: null,
            truckTypeName: null,
            truckerId: null,
            truckerName: null,
            vanType: null,
            plateType: 0,
            year: null,
            make: null,
            body: null,
            weightCapacity: null,
            chassisNumber: null,
            engineNumber: null,
            companyName: null,
            ownershipTypeId: null,
            owner: null,
            crNumber: null,
            crDate: null,
            orNumber: null,
            orDate: null,
            acquisitionDate: null,
            AcquisitionCost: null,
            emissionResult: false,
            emissionDate: null,
            hasGPS: false,
            gpsProvider: null,
            odoMeter: 0,
            fuelType: null,
            aveKMiliter: null,
            insurer: null,
            coverage: null,
            insuranceExpiryDate: null,
            policyNumber: null,
            status: true,
            deployment: null,
            garage: null,
            isForBilling: false,
            ltfrbExpirationDate: null,
            remarks: null,
        })
        this.displayDropdown = false;
    }

    toggleFormControls(disable: boolean): void {
        Object.keys(this.truckForm.controls).forEach(controlName => {
            const control = this.truckForm.get(controlName);
            if (control) {
                if (disable) {
                    control.disable({ onlySelf: true });
                } else {
                    control.enable({ onlySelf: true });
                }
            }
        });
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
    }

    onEditDriver(): void {
        this.displayDropdown = false;
        this.fieldDisabled = false; // Enable fields for editing
        this.toggleFormControls(false); // Enable form controls

        if (this.gridApi) {
            console.log('Grid API:', this.gridApi);
            this.gridApi.refreshCells({ force: true }); // Refresh grid to apply the changes
        } else {
            console.warn('Grid API is not initialized yet.');
        }
    }


    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        this.displayDropdown = false;
    }

    // START EMPLOYEE STATUS DROPDOWN
    selectTruckStatus(value: any): void {
        this.truckForm.get('status')?.setValue(value);
    }
    getTruckStatusLabel(): string {
        const statusValue = this.truckForm?.get('status')?.value;
        const selectedOption = this.truckStatusOptions?.find(option => option.value === statusValue);
        return selectedOption ? selectedOption.label : 'Select';
    }
    selectOwnerShip(value: any): void {
        this.truckForm.get('ownershipTypeId')?.setValue(value);
    }
    getOwnerShipTypeIdLabel(): string {
        const statusValue = this.truckForm?.get('ownershipTypeId')?.value;
        const selectedOption = this.ownerShipOptions?.find(option => option.id === statusValue);
        return selectedOption ? selectedOption.label : 'Select';
    }
    // START DATE FORMAT

    dateFormatter(params: any) {
        const date = params.value;
        if (!date) {
            return ''; // Display nothing if the date is null or undefined
        }
        return new Date(date).toLocaleDateString(); // Format the date if it's valid
    }
    // END DATE FORMAT

    showTagging() {
        this.showMovementHistoryTable = false
        this.showTaggingTable = true
        this.getData()
        this.getColumnDef()
    }
    // showMovementHistory() {
    //     this.showMovementHistoryTable = true;
    //     this.showTaggingTable = false;

    //     // Only fetch movement history if we have a valid truck ID
    //     if (this.idFromTruckList) {
    //         this.truckMovementHistory.getAllTruckMovementHistoryWithTruckId(this.idFromTruckList).subscribe({
    //             next: (data) => {
    //                 this.dataFromTruckMovementHistory = data;
    //                 console.log('Movement history:', data);
    //             },
    //             error: (err) => {
    //                 console.error('Error fetching movement history:', err);
    //             }
    //         });
    //     }
    // }
    showMovementHistory() {
        this.showMovementHistoryTable = true;
        this.showTaggingTable = false;

        // Retrieve the truckId from the form or fallback to idFromTruckList
        const truckId = this.truckForm.get('truckId')?.value || this.idFromTruckList;

        // Only make the service call if truckId is available
        if (truckId) {
            this.truckMovementHistory.getAllTruckMovementHistoryWithTruckId(truckId).subscribe({
                next: (data) => {
                    this.dataFromTruckMovementHistory = data;
                    console.log('Movement history:', data);
                },
                error: (err) => {
                    console.error('Error fetching movement history:', err);
                }
            });
        } else {
            console.warn('Truck ID is missing, cannot fetch movement history.');
        }
    }
}