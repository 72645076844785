import { Component, OnInit, HostListener } from '@angular/core';
import { Driver } from '../../../model/driver.model';
import { DriverService } from '../../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterCriteria } from '../../../model/filterCriteria.model';
import { RowDoubleClickedEvent, GridApi } from 'ag-grid-community';
import { TruckerService } from '../../../service/trucker.service';
import { TokenService } from '../../../service/token.service';
import { userAccess } from '../../../model/userAccess.model';
@Component({
    selector: 'app-driverlist',
    templateUrl: './driverlist.component.html',
    styleUrls: ['./driverlist.component.css'],
})
export class DriverlistComponent implements OnInit {
    columnDefs: ColDef[] = [
        { field: 'firstName', headerName: 'First Name' },
        { field: 'middleName', headerName: 'Middle Name' },
        { field: 'lastName', headerName: 'Last Name' },
        { field: 'truckerName', headerName: 'Trucker Name' },
        { field: 'licenseNo', headerName: 'License No.' },
        { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter },
        { field: 'status', headerName: 'Status' },
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
    };
    userHasEditAccess: boolean = false;
    userHasCreateAccess: boolean = false;
    userHasViewAccess: boolean = false;
    userHasDeleteAccess: boolean = false;
    Token: string = '';
    access: any[] = [];
    rowData: any[] = [];
    menuId: number = 0;
    myToken: string = '';
    filteredRowData: any[] = [];
    drivers: Driver[] = [];
    selectedDriver: Driver | null = null;
    selectedFilter: string | null = null;
    searchDriver: string = '';
    displayDropdown = false;
    contextMenuPosition = { x: '0px', y: '0px' };
    searchForm: FormGroup;
    criteriaList: FilterCriteria[] = [];
    isLoading: boolean = false
    constructor(private driverService: DriverService, private truckerService: TruckerService, private router: Router, private fb: FormBuilder, private tokenService: TokenService, private route: ActivatedRoute) {
        this.searchForm = this.fb.group({
            selectedOption: ['firstName'],
            firstNameFrom: [''],
            firstNameTo: [''],
            lastNameFrom: [''],
            lastNameTo: [''],
            middleNameFrom: [''],
            middleNameTo: [''],
            truckerNameFrom: [''],
            truckerNameTo: [''],
            licenseNoFrom: [''],
            licenseNoTo: [''],
            startDate: [''],
            endDate: ['']
        });
    }
    // START FETCH ALL DRIVER
    ngOnInit(): void {
        // this.Token = 'fLYc9HidbiB0c36X3PN9XbkmdGCOvSQAoW9PhxsFwLc=3d6b5c3e-bbbb-4124-89b7-6e4816957790tsaf'
        this.route.queryParams.subscribe((params) => {
            if (params['config']) {
                try {
                    // Decode and parse the 'config' from query params
                    const config = JSON.parse(decodeURIComponent(params['config']));
                    console.log('Token stored:', config.Id);
                    console.log('Menuid stored:', 'menuId:', params['menuId']);
                    // Set the token using the token service
                    this.tokenService.setToken(config.Id);
                    this.myToken = config.Id
                    this.menuId = params['menuId']
                    // Now that the token is set, proceed to call getAccess with the token
                    this.tokenService.getAccess(config.Id, this.menuId).subscribe({
                        next: (data: userAccess[]) => {
                            console.log('Access data received:', data);
                            this.access = data;
                            this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                            this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                            this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                            this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
                        },
                        error: (err) => console.error('Error loading access data:', err),
                    });
                } catch (error) {
                    console.error('Error parsing config JSON:', error);
                }
            } else {
                console.warn("Config parameter missing from URL query params.");
            }
        });


        const storedCriteriaList = JSON.parse(localStorage.getItem('criteriaList') || '[]');
        if (storedCriteriaList.length > 0) {
            this.criteriaList = storedCriteriaList; // Set the criteria list from local storage
            this.searchDrivers(); // Search with the retrieved criteria
        }
        this.driverService.getAllDrivers().subscribe({
            next: (data) => {
                this.rowData = data;
            },
            error: (response) => {
                console.error('Error fetching drivers:', response);
            },
        });
    }

    // END FETCH ALL DRIVER

    // START DATE FORMAT
    dateFormatter(params: any): string {
        const date = new Date(params.value);
        if (!isNaN(date.getTime())) {
            return date.toLocaleDateString();
        }
        return params.value;
    }
    // END DATE FORMAT

    // START RIGHT CLICK CONTEXT MENU
    onRightClick(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition.x = `${event.clientX}px`;
        this.contextMenuPosition.y = `${event.clientY}px`;
        this.displayDropdown = true;
    }
    onEditDriver() {
        console.log(this.myToken)
        if (this.selectedDriver) {
            this.router.navigate(['/driver/form'], { state: { fieldDisabled: false, driverId: this.selectedDriver } });
            console.log("selected ", this.selectedDriver)
        }
        this.displayDropdown = false;
    }

    private gridApi!: GridApi;

    // Method to handle row click event
    onRowDoubleClicked(event: RowDoubleClickedEvent<any>) {
        const rowData = event.data.id;  // The data of the clicked row
        if (this.userHasViewAccess) {
            if (rowData) {
                this.router.navigate(['/driver/form/',], { state: { fieldDisabled: true, driverId: rowData } });
            } else {
                console.error('Row data or ID is undefined.');
            }
        } else {
            console.log("no permission to view")
        }
    }
    onGridReady(params: { api: GridApi }) {
        this.gridApi = params.api;
    }

    onCellContextMenu(event: CellContextMenuEvent) {
        if (event && event.event) {
            this.selectedDriver = event.node.data.id;
            this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
            this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
            this.displayDropdown = true;
            event.event.preventDefault();
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        this.displayDropdown = false;
    }
    // END RIGHT CLICK CONTEXT MENU

    // START DROPDOWN OF CRITERIA
    selectFilter(event: Event) {
        const target = event.target as HTMLSelectElement;
        this.selectedFilter = target.value;
    }

    getCriteriaLabel(criteria: FilterCriteria): string {
        switch (criteria.selectedOption) {
            case 'firstName':
                return `First Name: ${criteria.firstNameFrom || ''} - ${criteria.firstNameTo || ''}`;
            case 'lastName':
                return `Last Name: ${criteria.lastNameFrom || ''} - ${criteria.lastNameTo || ''}`;
            case 'middleName':
                return `Middle Name: ${criteria.middleNameFrom || ''} - ${criteria.middleNameTo || ''}`;
            case 'createdDate':
                return `Created Date: ${criteria.startDate ? criteria.startDate.toLocaleDateString() : ''} - ${criteria.endDate ? criteria.endDate.toLocaleDateString() : ''}`;
            case 'truckerName':
                return `Trucker Name: ${criteria.truckerNameFrom || ''} - ${criteria.truckerNameTo || ''}`;
            case 'licenseNo':
                return `License No: ${criteria.licenseNoFrom || ''} - ${criteria.licenseNoTo || ''}`;
            default:
                return '';
        }
    }
    // END DROPDOWN OF CRITERIA

    //START SET FROM OR TO SAME VALUE IF ONE FIELDS IS MISSING
    private setDefaultToValues(criteria: FilterCriteria) {
        if (!criteria.firstNameTo) {
            criteria.firstNameTo = criteria.firstNameFrom;
        } else if (!criteria.firstNameFrom) {
            criteria.firstNameFrom = criteria.firstNameTo;
        }
        if (!criteria.lastNameTo) {
            criteria.lastNameTo = criteria.lastNameFrom;
        } else if (!criteria.lastNameFrom) {
            criteria.lastNameFrom = criteria.lastNameTo;
        }
        if (!criteria.middleNameTo) {
            criteria.middleNameTo = criteria.middleNameFrom;
        } else if (!criteria.middleNameFrom) {
            criteria.middleNameFrom = criteria.middleNameTo;
        }
        if (!criteria.licenseNoTo) {
            criteria.licenseNoTo = criteria.licenseNoFrom;
        } else if (!criteria.licenseNoFrom) {
            criteria.licenseNoFrom = criteria.licenseNoTo;
        }
        if (!criteria.truckerNameTo) {
            criteria.truckerNameTo = criteria.truckerNameFrom;
        } else if (!criteria.truckerNameFrom) {
            criteria.truckerNameFrom = criteria.truckerNameTo;
        }
        if (!criteria.endDate) {
            criteria.endDate = criteria.startDate;
        } else if (!criteria.startDate) {
            criteria.startDate = criteria.endDate;
        }
    }
    //END SET FROM OR TO SAME VALUE IF ONE FIELDS IS MISSING

    //START REMOVE INPUTS WHEN CRITERIA IS REMOVED
    addCriteria() {
        // Check if the search form is null, empty, or invalid
        const criteria: FilterCriteria = this.searchForm.value;

        this.setDefaultToValues(criteria);

        // Convert to number if necessary
        criteria.truckerNameFrom = criteria.truckerNameFrom ? Number(criteria.truckerNameFrom) : null;
        criteria.truckerNameTo = criteria.truckerNameTo ? Number(criteria.truckerNameTo) : null;

        // Check if the criteria already exists
        const existingIndex = this.criteriaList.findIndex(
            (item) => item.selectedOption === criteria.selectedOption
        );

        if (existingIndex !== -1) {
            this.criteriaList[existingIndex] = criteria;
        } else {
            this.criteriaList.push(criteria);
        }
        // Store updated criteria in local storage
        localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));
    }

    // END ADD CRITERIA

    // START SEARCH ALL OF LIST IN CRITERIA FILTER
    onSearch() {
        if (this.criteriaList.length === 0) {
            this.filteredRowData = [];
            return;
        }
        this.isLoading = true
        this.driverService.searchDrivers(this.criteriaList).subscribe({
            next: (data) => {
                this.filteredRowData = data;
                if (this.filteredRowData) {
                    this.isLoading = false
                }
                console.log(this.filteredRowData)
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    searchDrivers() {
        this.driverService.searchDrivers(this.criteriaList).subscribe({
            next: (data) => {
                this.filteredRowData = data;
                if (this.filteredRowData) {
                    this.isLoading = false
                }
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    // END SEARCH ALL OF LIST IN CRITERIA FILTER

    //START REMOVE INPUTS WHEN CRITERIA IS REMOVED
    resetFormControls(selectedOption: string) {
        switch (selectedOption) {
            case 'firstName':
                this.searchForm.patchValue({
                    firstNameFrom: '',
                    firstNameTo: ''
                });
                break;
            case 'lastName':
                this.searchForm.patchValue({
                    lastNameFrom: '',
                    lastNameTo: ''
                });
                break;
            case 'middleName':
                this.searchForm.patchValue({
                    middleNameFrom: '',
                    middleNameTo: ''
                });
                break;
            case 'truckerName':
                this.searchForm.patchValue({
                    truckerNameFrom: '',
                    truckerNameTo: ''
                });
                break;
            case 'licenseNo':
                this.searchForm.patchValue({
                    licenseNoFrom: '',
                    licenseNoTo: ''
                });
                break;
            case 'createdDate':
                this.searchForm.patchValue({
                    startDate: '',
                    endDate: ''
                });
                break;
            default:
                break;
        }
    }

    removeCriteria(index: number) {
        // Identify the criterion to remove
        const removedCriterion = this.criteriaList[index];

        // Remove the criterion from the list
        this.criteriaList.splice(index, 1);

        // Reset the form controls based on the removed criterion
        this.resetFormControls(removedCriterion.selectedOption);
        localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));

        // Perform search with updated criteria list
        // this.onSearch();
    }
    //END REMOVE INPUTS WHEN CRITERIA IS REMOVED
    @HostListener('window:beforeunload', ['$event'])
    clearLocalStorage(event: Event): void {
        localStorage.clear();  // Clears all localStorage data
    }
}
