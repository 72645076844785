import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Driver } from '../model/driver.model';
import { Observable } from 'rxjs';
import { FilterCriteria } from '../model/filterCriteria.model';
import { DriverBlackListType } from '../model/driverBlackListType.model';
import { Trucker } from '../model/trucker.model';
import { DriverBlacklistType } from '../model/driverBlacklist.model';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root'
})
export class DriverService {

  baseApiUrl: string = environment.baseApiUrl
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getAllDrivers(): Observable<Driver[]> {
    return this.http.get<Driver[]>(this.baseApiUrl + "/api/Driver")
  }

  getAllTruckers(): Observable<Trucker[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from TokenService
    });
    return this.http.get<Trucker[]>(`${this.baseApiUrl}/api/Trucker`, { headers });
  }

  getAllBlacklist(): Observable<DriverBlacklistType[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from TokenService
    });
    return this.http.get<DriverBlacklistType[]>(`${this.baseApiUrl}/api/driver-blacklist`, { headers })
  }

  addDriver(driver: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from TokenService
    });
    return this.http.post<any>(`${this.baseApiUrl}/api/driver`, driver, { headers });
  }
  addBlacklist(driverBlacklist: any, jwt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwt}`
    })
    return this.http.post(`${this.baseApiUrl}/api/driver-blacklist`, driverBlacklist, { headers });
  }
  searchBlackList(criteriaList: FilterCriteria[]) {
    return this.http.post<FilterCriteria[]>(`${this.baseApiUrl}/api/blacklist/filter`, criteriaList);
  }

  getBlackListById(blackListId: string, jwt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwt}`
    });
    return this.http.get(`${this.baseApiUrl}/edit-blacklist/${blackListId}`, { headers });
  }

  updateDriver(driverId: string, driver: any): Observable<any> {
    return this.http.put(this.baseApiUrl + '/edit-driver/' + driverId, driver);
  }

  updateBlacklist(blackListId: string, driverBlacklist: any, jwt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwt}`
    });
    return this.http.put(`${this.baseApiUrl}/edit-blacklist/${blackListId}`, driverBlacklist, { headers });

  }
  searchDrivers(criteriaList: FilterCriteria[]) {
    return this.http.post<FilterCriteria[]>(`${this.baseApiUrl}/api/driver/filter`, criteriaList);
  }
  // getDriverById(driverId: string): Observable<any> {
  //   return this.http.get(this.baseApiUrl + '/edit-driver/' + driverId);
  // }
  getDriverById(driverId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from your token service
    });
    return this.http.get(`${this.baseApiUrl}/edit-driver/${driverId}`, { headers });
  }
}
