import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { TruckType } from '../model/truckType.model';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class TrucktypeService {
  baseApiUrl: string = environment.baseApiUrl

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getTruckById(truckId: string): Observable<any> {
    return this.http.get(this.baseApiUrl + '/edit-truck/' + truckId);
  }


  getAllTruckType(): Observable<TruckType[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from your token service
    });
    return this.http.get<TruckType[]>(`${this.baseApiUrl}/api/TruckType`, { headers });
  }

}
