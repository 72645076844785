import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { Trucker } from '../model/trucker.model';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root'
})
export class TruckerService {

  baseApiUrl: string = environment.baseApiUrl


  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getAllTruckers(): Observable<Trucker[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`,
    });
    return this.http.get<Trucker[]>(`${this.baseApiUrl}/api/Trucker`, { headers });
  }
}
