<!-- <div *ngIf="show" class="toast show align-items-center text-white bg-{{status}} border-0" role="alert"
    aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <img src="..." class="rounded me-2" alt="...">
        <strong class="me-auto">{{ header }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
        {{ message }}
    </div>
</div> -->
<div *ngIf="show" class="alert alert-{{status}} d-flex justify-content-between" role="alert">
    <div>
        <strong class="me-auto">{{ header }}</strong>
        {{ message }}
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>