import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  @Input() title: string = '';
  @Input() tabPages: string[] = [];
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange = new EventEmitter<number>();
  @Output() tabChange = new EventEmitter<number>();

  setSelectedTab(index: number): void {
    this.selectedTabIndex = index;
    this.selectedTabIndexChange.emit(index);

    this.tabChange.emit(index);
  }
}
