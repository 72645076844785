import { Component } from '@angular/core';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrl: './blacklist.component.css'
})
export class BlacklistComponent {

}
