import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { BusinessUnit } from '../model/businessUnit.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessunitService {
  baseApiUrl: string = environment.baseApiUrl
  constructor(private http: HttpClient) { }

  GetAllNumberSixBusinessUnitTypeId(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(this.baseApiUrl + "/api/businessUnitTypeId")
  }
  updateBusinessUnit(businessUnit: any): Observable<any> {
    return this.http.put(this.baseApiUrl + "/edit-businessUnit/", businessUnit);
  }
}
