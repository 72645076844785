import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '../../../service/driver.service';
import { Driver } from '../../../model/driver.model';
import { Trucker } from '../../../model/trucker.model';
import { ToastComponent } from '../../toast/toast.component';
import { TruckerService } from '../../../service/trucker.service';
declare var bootstrap: any;

@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3';
  docInputStyle = 'row align-items-center d-flex flex-md-row mb-3';

  drivers: Driver[] = [];
  trucker: Trucker[] = [];
  employmentStatuses: boolean[] = [true, false];
  searchDriver: string = '';
  selectedDriver: any[] = [];
  filteredDriver: Driver[] = [];
  licenseRestriction: string[] = ['1', '2'];
  selectedEmploymentStatus: boolean | undefined;
  selectedlicenseTypeId: number | undefined;
  selectedLicenseRestriction: string | undefined;
  isSubmited: boolean = false


  driverDetails: Driver = {
    id: '',
    firstName: '',
    lastName: '',
    middleName: '',
    nickName: '',
    contactNumber: '',
    yearsOfService: '',
    truckerId: 0,
    licenseNo: '',
    licenseExpiry: '',
    licenseType: '',
    fileNumber: '',
    filePath: '',
    licenseNoFilePath: '',
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    createdByUserId: 0,
    lastUpdatedByUserId: 0,
    status: false,
    driverBlacklistTypeId: 0,
    hasBiodata: false,
    hasNBIClearance: false,
    hasBarangayClearance: false,
    hasPoliceClearance: false,
    hasPreDefensiveDriveCourse: false,
    hasDefensiveDriveCourse: false,
    hasSHE: false,
    hasGoodWarehousePractice: false,
    apeDate: '',
    drugTestDate: ''
  };
  truckerDetails: Trucker = {
    id: '',
    name: ''
  }

  // START TOAST
  showToast(message: string, header: string, status: string) {
    this.toastComponent.message = message;
    this.toastComponent.header = header;
    this.toastComponent.status = status;
    this.toastComponent.showToast();
  }
  // END TOAST

  selectEmploymentStatus(status: boolean) {
    this.selectedEmploymentStatus = status;
    this.driverDetails.status = status;
  }

  selectLicenseRestriction(status: string) {
    this.selectedLicenseRestriction = status;
  }

  constructor(private route: ActivatedRoute, private driverService: DriverService, private truckerService: TruckerService, private router: Router) { }

  private formatDate(date: any): string {
    return date ? new Date(date).toISOString().split('T')[0] : '';
  }

  //START GET THE DATA OF SPECIFIC DRIVER WHEN VIEWED
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (params) => {
        const id = params.get('id');
        if (id) {
          this.driverService.getDriver(id)
            .subscribe({
              next: (response) => {
                response.licenseExpiry = this.formatDate(response.licenseExpiry);
                response.apeDate = this.formatDate(response.apeDate);
                response.drugTestDate = this.formatDate(response.drugTestDate);
                this.driverDetails = response;
                this.truckerDetails = response;
              }
            });
        }
      }
    });
  }
  //END GET THE DATA OF SPECIFIC DRIVER WHEN VIEWED

  // STAR MODAL FOR TRUCKER ID
  openModal() {
    const modalElement = document.getElementById('exampleModal');
    const modalInstance = new bootstrap.Modal(modalElement);
    modalInstance.show();
    this.getTrucker();
  }

  closeModal() {
    const modalElement = document.getElementById('exampleModal');
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }
  // STAR MODAL FOR TRUCKER ID

  //START GET THE DATA OF TRUCKERS
  getTrucker() {
    this.truckerService.getAllTruckers().subscribe({
      next: (data) => {
        this.trucker = data;
      }
    });
  }
  //START GET THE DATA OF TRUCKERS

  //START SEARCH
  onSearch() {
    if (this.searchDriver) {
      this.filteredDriver = this.drivers.filter(driver =>
        driver.firstName?.toLowerCase().includes(this.searchDriver.toLowerCase())
      );
    } else {
      this.filteredDriver = this.drivers;
    }
  }

  onkeypress(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.onSearch();
    }
  }
  //START SEARCH

  // START UPDATE THE DRIVER INFO
  updateDriver() {
    this.isSubmited = true
    if (this.driverDetails.truckerId === 0 || this.driverDetails.truckerId === null || this.driverDetails.truckerId === undefined) {
      this.showToast('Please Fill-out all required entry, Thank you', 'Failed', 'danger');
    }
    else if (this.driverDetails.id) {
      this.showToast('Driver Successfully Added', 'Successful', 'success');
      this.driverService.updateDriver(this.driverDetails.id, this.driverDetails).subscribe({
        next: (res) => {
          setTimeout(() => {
            this.router.navigate(['driver'])
          }, 500);
        },
        error: (err) => {
          console.error('Error updating driver:', err);
        }
      });
    }
  }

  selectedTrucker(trucker: any) {
    this.driverDetails.truckerId = trucker.id;
    this.truckerDetails.name = trucker.name;
    this.closeModal();
  }
  // END UPDATE THE DRIVER INFO
  onCancel() {
    this.router.navigate(['driver']);
  }
}
