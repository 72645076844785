import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { Truck } from '../model/truck.model';
import { TruckFilterCriteria } from '../model/truckFilterCriteria.model';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root'
})
export class TruckService {
  baseApiUrl: string = environment.baseApiUrl
  constructor(private http: HttpClient, private tokenService: TokenService) { }


  updateTruck(truckId: string, truck: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`,
    });
    return this.http.put(`${this.baseApiUrl}/edit-truck/${truckId}`, truck, { headers });
  }
  getTruckById(truckId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from your token service
    });
    return this.http.get(`${this.baseApiUrl}/edit-truck/${truckId}`, { headers });
  }
  getAllTruck(): Observable<Truck[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getJwt()}`, // Retrieve token from TokenService
    });
    return this.http.get<Truck[]>(`${this.baseApiUrl}/api/Truck`, { headers });
  }
  // getAllTruck() {
  //   return this.http.get<Truck[]>(this.baseApiUrl + "/api/Truck")
  // }
  addTruck(truck: any): Observable<any> {
    return this.http.post(this.baseApiUrl + '/api/truck', truck);
  }
  searchTruck(criteriaList: TruckFilterCriteria[]): Observable<Truck[]> {
    return this.http.post<Truck[]>(`${this.baseApiUrl}/api/truck/filter`, criteriaList);
  }
}
