import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { TruckOperationSiteTagging } from '../model/truckOperationTagging.model';
import { TruckTagginAndBusinessUnit } from '../model/truckTragginAndBusinessUnit.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TruckOperationSiteTaggingService {
  baseApiUrl: string = environment.baseApiUrl

  constructor(private http: HttpClient) { }

  getAllTruckOps() {
    return this.http.get<TruckOperationSiteTagging[]>(this.baseApiUrl + '/api/TruckOperationSiteTagging');
  }

  getBusinessUnitAndTruckOps() {
    return this.http.get<TruckTagginAndBusinessUnit[]>(`${this.baseApiUrl}/api/GetAllBusinessUnits`);
  }
  getBusinessUnitAndTruckOpsLinq(truckId: string) {
    return this.http.get<TruckTagginAndBusinessUnit[]>(`${this.baseApiUrl}/api/GetAllTruckOperationSiteTaggingWithBusinessUnit/` + truckId);
  }

  addTruckOperationSiteTagging(truckOps: any[]) {
    return this.http.post(this.baseApiUrl + '/api/TruckOperationSiteTagging', truckOps);
  }

  updateOrAddTruckOperationSiteTagging(truckOps: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/api/UpdateOrAddTruckOperationSiteTagging`, truckOps)
  }

}
