import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { TruckMovementHistory } from '../model/truckMovementHistory.model';
@Injectable({
  providedIn: 'root'
})
export class TruckmovementhistoryService {
  baseApiUrl: string = environment.baseApiUrl

  constructor(private http: HttpClient) { }


  getAllTruckMovementHistoryWithTruckId(truckId: string) {
    return this.http.get<TruckMovementHistory[]>(this.baseApiUrl + '/api/truckmovementhistory/' + truckId);
  }


}
