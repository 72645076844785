import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { DriverService } from '../../service/driver.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColDef, CellContextMenuEvent } from 'ag-grid-community';
import { RowDoubleClickedEvent, GridApi } from 'ag-grid-community';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DriverBlacklistType } from '../../model/driverBlacklist.model';
import { TokenService } from '../../service/token.service';
import { ToastComponent } from '../toast/toast.component';
import { blackListFilter } from '../../model/blackListFilterCriteria.model';
@Component({
  selector: 'app-driver-blacklist',
  templateUrl: './driver-blacklist.component.html',
  styleUrls: ['./driver-blacklist.component.css'],
})

export class DriverBlacklistComponent implements OnInit {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  constructor(private driverService: DriverService, private router: Router, private route: ActivatedRoute,
    private fb: FormBuilder, private tokenService: TokenService) {
    this.searchForm = this.fb.group({
      selectedOption: ['description'],
      descriptionFrom: [''],
      descriptionTo: [''],
      status: [''],
      startDate: [''],
      endDate: [''],
    });
  }

  searchForm: FormGroup;
  Token: string = '';
  userHasEditAccess: boolean = false;
  userHasCreateAccess: boolean = false;
  userHasViewAccess: boolean = false;
  userHasDeleteAccess: boolean = false;
  selectedBlackListId: string | null = null;
  status: boolean = false;
  access: any[] = [];
  menuId: number = 3
  private gridApi: GridApi | undefined;
  currentTabIndex: number = 0;
  isEditMode: boolean = false
  driverBlacklistForm: FormGroup = new FormGroup({
    description: new FormControl({ value: '', disabled: false }),
    status: new FormControl({ value: true, disabled: false }),
    createdByUserId: new FormControl({ value: '', disabled: false }),
    createdDate: new FormControl({ value: '', disabled: false }),
    lastUpdatedByUserId: new FormControl({ value: '', disabled: false }),
    lastUpdatedDate: new FormControl({ value: '', disabled: false }),
  })
  // openAddBlacklist() {
  //   this.dialogRef.open(AddDriverBlacklistComponent, {
  //     data: {
  //       blacklistType: null
  //     }
  //   });
  // }

  // onEditBlacklist() {
  //   this.dialogRef.open(AddDriverBlacklistComponent, {
  //     data: {
  //       blacklistType: this.selectedBlacklistType
  //     }
  //   });
  // }


  columnDefs: ColDef[] = [
    { field: 'description', headerName: 'description' },
    { field: 'status', headerName: 'Status' },
    { field: 'createdByUserId', headerName: 'Created By' },
    { field: 'createdDate', headerName: 'Created Date', valueFormatter: this.dateFormatter, floatingFilter: false, filter: false },
    { field: 'lastUpdatedByUserId', headerName: 'Updated By' },
    { field: 'lastUpdatedDate', headerName: 'Updated Date', valueFormatter: this.dateFormatter, floatingFilter: false, filter: false },
  ];
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  };
  rowData: any[] = [];
  filteredRowData: any[] = [];
  blacklist: DriverBlacklistType[] = [];
  selectedBlacklistType: DriverBlacklistType | null = null;
  selectedFilter: string | null = null;
  searchBlacklistType: string = '';
  displayDropdown = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  isSearchNotNull: boolean = true;
  isClear: boolean = false;
  loading: boolean = true;
  isNotif: boolean = false;
  notif: any = {
    header: '',
    status: '', // Can be 'success', 'error', 'info', etc.
    message: ''
  };
  notifMessage: string = '';
  notifHeader: string = '';
  notifStatus: string = '';
  selectedBlacklist: DriverBlacklistType | null = null;
  criteriaList: any[] = [];
  dateError: boolean = false
  myToken: string = '';
  blacklistId: string | null = null;
  jwt: string = '';
  inputStyle = 'row align-items-center d-flex flex-column flex-md-row mb-3'
  showToast(message: string, header: string, status: string) {
    this.toastComponent.message = message;
    this.toastComponent.header = header;
    this.toastComponent.status = status;
    this.toastComponent.showToast();
  }
  updateNotification(message: string, header: string, status: string,) {
    this.isNotif = true;
    this.notif.header = header;
    this.notif.status = status;
    this.notif.message = message;
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['config']) {
        try {
          const config = JSON.parse(decodeURIComponent(params['config']));
          const token = config?.Id; // Extract `authToken` from params
          const menuId = params['menuId']; // Extract menu ID
          if (!token) {
            this.isNotif = true
            this.updateNotification('please contact the developers', 'Failed', 'danger')
            return;
          }

          if (!menuId) {
            this.isNotif = true
            this.updateNotification('please contact the developers', 'Failed', 'danger')
            return;
          }

          this.myToken = token;
          this.menuId = menuId;

          // Fetch user access and JWT token from the backend
          this.tokenService.getAccess(token, menuId).subscribe({
            next: (data: any) => {
              const jwtToken = data?.token; // JWT from backend
              const authToken = token; // Auth Token from params
              if (jwtToken) {
                this.tokenService.setToken(jwtToken, authToken); // Store both tokens
                this.jwt = jwtToken;
              } else {
                if (data) {
                  this.isNotif = true
                  this.updateNotification('to access please contact the developers', 'Failed', 'danger')
                }
                // this.showToast('No Token', 'Failed', 'danger'); // error auth token
                // console.error('JWT Token missing in access data.');
              }
              if (data?.access && Array.isArray(data.access)) {
                this.access = data.access;
                this.userHasEditAccess = this.access.some((item) => item.access === 'EDIT');
                this.userHasCreateAccess = this.access.some((item) => item.access === 'CREATE');
                this.userHasViewAccess = this.access.some((item) => item.access === 'VIEW');
                this.userHasDeleteAccess = this.access.some((item) => item.access === 'DELETE');
              } else {
                // console.error('Expected access array but received:', data);
                this.access = [];
              }

            },
            error: (err) => {
              this.isNotif = true
              this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
            },
          });
        } catch (error) {
          this.isNotif = true
          this.updateNotification('please contact the developers, thank you.', 'Failed', 'danger');
        }
      } else {
        this.tokenService.clearTokens()
        this.isNotif = true
        this.updateNotification('to access the page please contact the developers, thank you.', 'Failed', 'danger');
        return;
      }
    });
  }
  fetchBlacklist(id: string | number) {
    if (!id && this.jwt === '') {
      this.updateNotification('blacklist ID', 'Invalid', 'warning');
      return;
    }
    const jwt = this.tokenService.getJwt();
    if (!jwt) {
      this.updateNotification('please contact developers', 'Failed', 'warning');
      return;
    }
    const blackListId = id; // Ensure it's a valid string
    this.driverService.getBlackListById(id?.toString(), jwt).subscribe({
      next: (data) => {
        this.driverBlacklistForm.patchValue({
          description: data.description,
          status: data.status,
        });
      }
    })
  }

  saveBlacklist() {
    const jwt = this.tokenService.getJwt();
    const createdUserById = this.access?.[0]?.user || null;
    const currentDate = new Date();
    if (!jwt) {
      this.isNotif = true
      this.updateNotification("Please contact developers", 'Failed', 'danger');
      return;
    }
    if (this.driverBlacklistForm.get('description')?.value === '') {
      this.isNotif = true
      this.updateNotification("Please enter a description.", 'Failed', 'danger');
      return;
    }



    //update
    if (this.selectedBlackListId) {
      this.driverBlacklistForm.patchValue({
        lastUpdatedDate: currentDate,
        lastUpdatedByUserId: createdUserById
      });
      const formValue = JSON.parse(JSON.stringify(this.driverBlacklistForm.value));
      delete formValue.createdByUserId;
      this.driverService.updateBlacklist(this.selectedBlackListId, formValue, jwt).subscribe({
        next: () => {
          window.scroll(0, 0);
          this.isNotif = true
          this.updateNotification('to update blacklist, sorry', 'Failed', 'danger');
          // this.showToast('Updated Driver, Thank you', 'Successfully', 'success');
        },
        error: (err) => {
          this.isNotif = true
          this.updateNotification('to update blacklist, sorry', 'Failed', 'danger');
        }
      });
      //add
    } else {
      this.driverBlacklistForm.patchValue({
        createdDate: currentDate,
        createdByUserId: createdUserById
      });

      this.isNotif = true
      if (this.isNotif && this.driverBlacklistForm.value.description != '' && this.jwt) {
        this.updateNotification('Added New blacklist, thank you', 'Successfully', 'success');
      } else {
        this.updateNotification('to add New blacklist, sorry', 'Failed', 'danger');
      }
      const formValue = JSON.parse(JSON.stringify(this.driverBlacklistForm.value));
      delete formValue.lastUpdatedByUserId;
      this.driverService.addBlacklist(formValue, jwt).subscribe({
        next: (data) => {
          window.scroll(0, 0);
        }
      })
    }
  }
  // saveBlacklist() {
  //   if (this.driverBlacklistForm.get('description')?.value === '') {
  //     this.showToast("Please enter a description.", 'Failed', 'danger');
  //     return;
  //   }

  //   const createdUserById = this.access?.[0]?.user || null; // Get the user ID
  //   const currentDate = new Date(); // Get the current date
  //   const jwt = this.tokenService.getJwt(); // Retrieve JWT

  //   if (!jwt) {
  //     console.error('JWT is missing,');
  //     return;
  //   }

  //   if (this.selectedBlackListId) {
  //     // Update existing record
  //     const updatedPayload = {
  //       ...this.driverBlacklistForm.value,
  //       lastUpdatedDate: currentDate,
  //       lastUpdatedByUserId: createdUserById
  //     };

  //     this.driverService.updateBlacklist(this.selectedBlackListId, updatedPayload, jwt).subscribe({
  //       next: () => {
  //         this.showToast('Updated Driver, Thank you', 'Successfully', 'success');
  //         window.scroll(0, 0);
  //       },
  //       error: (err) => {
  //         console.error('Failed to update blacklist:', err);
  //       }
  //     });
  //   } else {
  //     // Add new record
  //     const newPayload = {
  //       ...this.driverBlacklistForm.value,
  //       createdDate: currentDate,
  //       createdByUserId: createdUserById // Correct casing for backend compatibility
  //     };

  //     delete newPayload.lastUpdatedByUserId; // Remove unnecessary field for new entries


  //     this.driverService.addBlacklist(newPayload, jwt).subscribe({
  //       next: (data) => {
  //         this.showToast('Added New Driver, Thank you', 'Successfully', 'success');
  //         window.scroll(0, 0);
  //       },
  //       error: (err) => {
  //         console.error('Failed to add blacklist:', err);
  //       }
  //     });
  //   }
  // }



  dateFormatter(params: any): string {
    const date = new Date(params.value);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString();
    }
    return params.value;
  }

  removeCriteria(index: number) {
    this.criteriaList.splice(index, 1);
    this.onSearch();
  }

  onSearch() {
    if (this.criteriaList.length === 0) {
      this.filteredRowData = [];
      return;
    }
    this.searchBlackList();
  }
  searchBlackList() {
    this.driverService.searchBlackList(this.criteriaList).subscribe({
      next: (data) => {
        this.filteredRowData = data;
      },
      error: (error) => {
      },
    });
  }
  selectFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedFilter = target.value;
  }
  getCriteriaLabel(criteria: blackListFilter): string {
    switch (criteria.selectedOption) {
      case 'plateNo':
        return `Plate No: ${criteria.descriptionFrom || ''} - ${criteria.descriptionTo || ''}`;
      case 'createdDate':
        return `Created Date: ${criteria.startDate ? criteria.startDate.toLocaleDateString() : ''} - ${criteria.endDate ? criteria.endDate.toLocaleDateString() : ''}`;
      default:
        return '';
    }
  }
  // START SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING
  private setDefaultToValues(criteria: blackListFilter) {
    if (!criteria.descriptionTo) {
      criteria.descriptionTo = criteria.descriptionFrom;
    } else if (!criteria.descriptionFrom) {
      criteria.descriptionFrom = criteria.descriptionTo;
    }
  }
  // END SET FROM OR TO SAME VALUE IF ONE FIELD IS MISSING
  // START ADD CRITERIA
  addCriteria() {
    const criteria: blackListFilter = this.searchForm.value;

    this.setDefaultToValues(criteria);
    const existingIndex = this.criteriaList.findIndex(
      (item) => item.selectedOption === criteria.selectedOption
    );

    if (existingIndex !== -1) {
      this.criteriaList[existingIndex] = criteria;
    } else {
      this.criteriaList.push(criteria);
    }

    // Store updated criteria in local storage
    localStorage.setItem('criteriaList', JSON.stringify(this.criteriaList));
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = (event as MouseEvent).clientX + 'px';
    this.contextMenuPosition.y = (event as MouseEvent).clientY + 'px';
    this.displayDropdown = true;
  }

  onCellContextMenu(event: CellContextMenuEvent) {
    if (event && event.event) {
      this.selectedBlacklist = event.node.data;
      this.contextMenuPosition.x = `${(event.event as MouseEvent).clientX}px`;
      this.contextMenuPosition.y = `${(event.event as MouseEvent).clientY}px`;
      this.displayDropdown = true;
      event.event.preventDefault();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    this.displayDropdown = false;
  }

  toggleFormControls(disable: boolean): void {
    Object.keys(this.driverBlacklistForm.controls).forEach(controlName => {
      const control = this.driverBlacklistForm.get(controlName);
      if (control) {
        if (disable) {
          control.disable({ onlySelf: true });
        } else {
          control.enable({ onlySelf: true });
        }
      }
    });
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
  }
  onRowDoubleClicked(event: RowDoubleClickedEvent<any>) {
    const rowData = event.data?.id; // Safely access row ID
    if (this.userHasViewAccess && this.jwt !== '') {
      // this.fieldDisabled = true
      this.status = true
      this.isNotif = false
      this.isEditMode = false;
      if (rowData) {
        this.selectedBlackListId = rowData; // Assign to shared property
        this.fetchBlacklist(rowData);
        this.currentTabIndex = 0; // Switch to Tab 1
        this.toggleFormControls(true);
      } else {
        // this.showToast("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
      }
    } else {
      // this.showToast("Sorry, you don't have permission to view the data", 'Failed to view', 'warning');
    }
  }

  onRightClickEdit() {
    if (this.selectedBlacklist && this.jwt != '') {
      const blacklistId = this.selectedBlacklist?.id?.toString() || '';
      this.isEditMode = true;
      this.selectedBlackListId = blacklistId
      // const driverId = this.selectedDriver.id ?? ""; // Extract `id` as a string
      // this.selectedBlackListId = blacklistString; // Assign the string `id` to the shared property
      if (blacklistId) {
        this.fetchBlacklist(blacklistId);
      }
      this.currentTabIndex = 0; // Switch to Tab 1
      this.toggleFormControls(false);
    }
    this.displayDropdown = false;
  }

  onFindBlacklist() {
    this.currentTabIndex = 1
    this.displayDropdown = false;
  }

  onRightEditBlacklist() {
    this.displayDropdown = false;
    this.status = true
    this.toggleFormControls(false);
    this.isEditMode = true;
  }
  onRightBlacklist() {
    this.toggleFormControls(false);
    this.status = false
    this.selectedBlackListId = null
    this.driverBlacklistForm.reset({
      description: '',
      status: true,
      createdDate: '',
      lastUpdatedDate: ''
    })
    this.isNotif = false
    this.displayDropdown = false;
  }
  onTabChange(index: number) {
    if (index === 0) {
      this.isNotif = false
      this.onRightBlacklist();
    }
  }
}
