import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DriverComponent } from './component/DriverMaintenance/driver/driver.component';
import { DriverlistComponent } from './component/DriverMaintenance/driverlist/driverlist.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DriverService } from './service/driver.service';
import { AddDriverComponent } from './component/DriverMaintenance/add-driver/add-driver.component';
import { EditDriverComponent } from './component/DriverMaintenance/edit-driver/edit-driver.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  // required for Angular Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from './component/toast/toast.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AddEditDriverComponent } from './component/DriverMaintenance/add-edit-driver/add-edit-driver.component';
import { AddEditTruckComponent } from './component/TruckMaintenance/add-edit-truck/add-edit-truck.component';
import { TrucklistComponent } from './component/TruckMaintenance/trucklist/trucklist.component';
import { AddDriverBlacklistComponent } from './component/add-driver-blacklist/add-driver-blacklist.component';
import { DriverBlacklistComponent } from './component/driver-blacklist/driver-blacklist.component';
import { BlacklistComponent } from './component/blacklist/blacklist.component';
import { TokenComponent } from './component/token/token.component';

@NgModule({
  declarations: [
    AppComponent,
    DriverComponent,
    DriverlistComponent,
    AddDriverComponent,
    EditDriverComponent,
    ToastComponent,
    AddEditDriverComponent,
    AddEditTruckComponent,
    TrucklistComponent,
    AddDriverBlacklistComponent,
    DriverBlacklistComponent,
    BlacklistComponent,
    TokenComponent,
  ],
  imports: [
    AgGridModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), DriverService, provideAnimationsAsync()],
  bootstrap: [AppComponent]
})
export class AppModule { }
