<section id="content" class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <div class="panel mb25 mt5">
        <div class="panel-heading">
          <span class="panel-title">
          </span>
          <div class="container-fluid border-bottom px-0">
            <div class="border border-bottom-0 p-2 bg-secondary-color py-3 header">
              <div class="d-flex justify-content-between align-items-center">
                <span class="fw-bold">{{title}}</span>
                <ul class="nav nav-underline">
                  <li class="nav-item" *ngFor="let tab of tabPages; let i = index"
                    [class.active]="selectedTabIndex === i" (click)="setSelectedTab(i)">
                    <a class="link-underline-opacity-0 link-underline text-secondary" href="javascript:void(0)">{{ tab
                      }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <ng-container *ngIf="selectedTabIndex === 0">
            <ng-content select="[tab1Content]"></ng-content>
          </ng-container>
          <ng-container *ngIf="selectedTabIndex === 1">
            <ng-content select="[tab2Content]"></ng-content>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>