import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { TruckType } from '../model/truckType.model';
@Injectable({
  providedIn: 'root'
})
export class TrucktypeService {
  baseApiUrl: string = environment.baseApiUrl

  constructor(private http: HttpClient) { }

  getTruckById(truckId: string): Observable<any> {
    return this.http.get(this.baseApiUrl + '/edit-truck/' + truckId);
  }
  getAllTruckType(): Observable<TruckType[]> {
    return this.http.get<TruckType[]>(this.baseApiUrl + "/api/TruckType")
  }
}
