import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Driver } from '../model/driver.model';
import { Observable } from 'rxjs';
import { FilterCriteria } from '../model/filterCriteria.model';
import { DriverBlackListType } from '../model/driverBlackListType.model';
import { Trucker } from '../model/trucker.model';
import { DriverBlacklistType } from '../model/driverBlacklist.model';
@Injectable({
  providedIn: 'root'
})
export class DriverService {

  baseApiUrl: string = environment.baseApiUrl
  constructor(private http: HttpClient) { }

  getAllDrivers(): Observable<Driver[]> {
    return this.http.get<Driver[]>(this.baseApiUrl + "/api/Driver")
  }
  
  getAllTruckers(): Observable<Trucker[]> {
    return this.http.get<Trucker[]>(this.baseApiUrl + "/api/Trucker")
  }
  getAllBlacklist(): Observable<DriverBlacklistType[]> {
    return this.http.get<DriverBlacklistType[]>(this.baseApiUrl + "/api/driver-blacklist")
  }
  getDriver(id: string): Observable<Driver> {
    return this.http.get<Driver>(this.baseApiUrl + '/edit-driver/' + id)
  }

  addDriver(driver: any): Observable<any> {
    return this.http.post(this.baseApiUrl + '/api/driver', driver);
  }
  
  addBlacklist(driverBlacklist: any): Observable<any> {
    return this.http.post(this.baseApiUrl + '/api/driver-blacklist', driverBlacklist);
  }

  updateDriver(driverId: string, driver: any): Observable<any> {
    return this.http.put(this.baseApiUrl + '/edit-driver/' + driverId, driver);
  }

  updateBlacklist(blacklistId: string, driverBlacklist: any): Observable<any> {
    return this.http.put(this.baseApiUrl + '/edit-blacklist/' + blacklistId,driverBlacklist);
  }
  searchDrivers(criteriaList:FilterCriteria[]){
    return this.http.post<FilterCriteria[]>(`${this.baseApiUrl}/api/driver/filter`, criteriaList);
  }
  getDriverById(driverId: string): Observable<any> {
    // return this.http.get(`${this.baseApiUrl}/${driverId}`);
    return this.http.get(this.baseApiUrl + '/edit-driver/' + driverId);
  }
}
