import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { Truck } from '../model/truck.model';
import { TruckFilterCriteria } from '../model/truckFilterCriteria.model';
@Injectable({
  providedIn: 'root'
})
export class TruckService {
  baseApiUrl: string = environment.baseApiUrl
  constructor(private http: HttpClient) { }


  updateTruck(truckId: string, truck: any): Observable<any> {
    return this.http.put(this.baseApiUrl + '/edit-truck/' + truckId, truck);
  }
  getTruckById(truckId: string): Observable<any> {
    return this.http.get(this.baseApiUrl + '/edit-truck/' + truckId);
  }
  getAllTruck() {
    return this.http.get<Truck[]>(this.baseApiUrl + "/api/Truck")
  }
  addTruck(truck: any): Observable<any> {
    return this.http.post(this.baseApiUrl + '/api/truck', truck);
  }
  searchTruck(criteriaList: TruckFilterCriteria[]): Observable<Truck[]> {
    return this.http.post<Truck[]>(`${this.baseApiUrl}/api/truck/filter`, criteriaList);
  }
}
