import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/dev';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { userAccess } from '../model/userAccess.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  baseApiUrl: string = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  // Fetch token from backend by tokenId
  fetchToken(tokenId: string): Observable<any> {
    return this.http.get(`${this.baseApiUrl}/api/token/${tokenId}`).pipe(
      tap(
        (response: any) => {
          if (response?.jwt && response?.authToken) {
            this.setToken(response.jwt, response.authToken);
          } else {
            console.error('JWT or Auth Token missing in response:', response);
          }
        },
        (error) => {
          console.error('Error fetching token:', error);
        }
      )
    );
  }

  // Save tokens to both localStorage and memory
  setToken(jwt: string, authToken: string): void {
    localStorage.setItem('jwt', jwt);
    localStorage.setItem('authToken', authToken);
  }

  // Retrieve JWT from memory or localStorage
  getJwt(): string | null {
    const jwt = localStorage.getItem('jwt');
    return jwt || null;
  }

  // Retrieve Auth Token from localStorage
  getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  // Clear tokens from memory and localStorage
  clearTokens(): void {
    localStorage.removeItem('jwt');
    localStorage.removeItem('authToken');
  }

  // Fetch user access data
  getAccess(token: string, menu: number): Observable<userAccess[]> {
    const url = `${this.baseApiUrl}/api/Access/token?config=${encodeURIComponent(
      token
    )}&menuId=${menu}`;
    return this.http.get<userAccess[]>(url);
  }

  // A utility method to ensure the token is present before proceeding
  ensureJwt(): string | null {
    let jwt = this.getJwt();
    if (!jwt) {
      console.error('JWT is missing. Please authenticate.');
    }
    return jwt;
  }
}
